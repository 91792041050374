<template>
<div class="bathroomContainer md-layout md-alignment-top-left">
  <hr class="md-layout-item md-size-100" v-if="index > 0">
  <v-layout wrap space-between>
    <v-flex xs10>

      <div class="title">
      {{$t("details.bathroom")}} {{index+1}}
    </div>
      <v-radio-group v-model="tBathroom.type" @change="onChange" >
        <v-radio v-for="(option, index) in bathroomOptions" color="primary" :key="index" :label="option.text" :value="option.value"></v-radio>
      </v-radio-group>
    </v-flex>
    <v-flex xs2 >
      <v-icon x-large color="red" @click="removeBathroom(index)" v-if="!disabledRemove">close</v-icon>
    </v-flex>
  </v-layout>
</div>
</template>
<script>
import Dropzone from '../Dropzone.vue'

export default {
  name: "",
  props: ['index', 'user', 'bathroom', 'uploading','disabledRemove'],
  data: () => ({
    tBathroom:{},
  }),
  methods: {
    removeBathroom(index) {
      this.$emit('removeBathroom', index)
    },
    onChange() {
      // console.log('changed');
      this.$emit('bathroomUpdated', this.tBathroom)
    }
  },
  computed:{
    bathroomOptions(){
      let t=['Wet Bathroom','Dry Bathroom']
      if (this!=undefined){
        t=[
          {
            text:this.$t('details.bathroomWet'),
            value:"Wet Bathroom"
          },
          {
            text:this.$t('details.bathroomDry'),
            value:"Dry Bathroom",
          }
        ]
      }
      return t
    },
  },
  created() {
    // console.log('this.bathroom', this.bathroom.type)
    this.tBathroom=this.bathroom
  },
  components: {
    Dropzone
  }
}
</script>
<style lang="scss" scoped>
.bathroomContainer{
  margin-bottom: 12px;
  width: 100%;
  button{
    margin: 0;
  }
  .md-layout-item-normal{
    flex: 0 1 auto!important;
  }
  .remove{
    padding-top:1em
  }
  .md-radio{
    margin-top: 2px;
  }
  h4{
    margin: 0;
    opacity: .5;
    display:inline-block;
    min-width: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  }
  </style>
