<template>
<div id="" class="roomContainer">
  <Navbar></Navbar>
  <v-container grid-list-xs>
    <Loading v-if="initialLoad"></Loading>
    <v-layout  wrap md9 xs11 justify-center v-if="!initialLoad">
      <div class="display-2">
        <h1 class="display-2">{{$t("uploads.room")}} {{'- ' +room.general.title || $v.room.general.title}}</h1>
        <v-btn color="primary" flat href="https://www.youtube.com/watch?v=yrOZuHRuf9M&t=99" target="blank">{{$t('newListing.howToAddRoom')}}</v-btn>
        <v-btn color="primary" flat :href="'/details/'+roomId" target="blank" v-if="roomDraft==false && user.roleValue>40">See Listing</v-btn>
      </div>
    </v-layout>
    <v-tabs md9 xs11 show-arrows centered color="primary" dark icons-and-text v-model="tabs">
      <v-tabs-slider color="white"></v-tabs-slider>
      <v-tab href="#tab-one">
        {{$t("uploads.info")}}
        <v-icon color="#ffffff">domain</v-icon>
        <v-badge v-if="room.general.complete" color="green">
          <v-icon slot="badge" color="#ffffff">done</v-icon>
        </v-badge>
      </v-tab>
      <v-tab href="#tab-two">
        {{$t("uploads.features")}}
        <v-icon>hotel</v-icon>
        <v-badge v-if="room.featureList.complete" color="green">
          <v-icon slot="badge">done</v-icon>
        </v-badge>
      </v-tab>
      <v-tab href="#tab-three">
        {{$t("uploads.billing")}}
        <v-icon>payment</v-icon>
        <v-badge v-if="room.billing.complete" color="green">
          <v-icon slot="badge">done</v-icon>
        </v-badge>
      </v-tab>
      <v-tab href="#tab-four">
        {{$t("uploads.tenants")}}
        <v-icon>people</v-icon>
        <v-badge v-if="room.tenants.complete!=''" color="green">
          <v-icon slot="badge">done</v-icon>
        </v-badge>
      </v-tab>
      <v-tab href="#tab-five">
        {{$t("uploads.photos")}}
        <v-icon>image</v-icon>
        <v-badge v-if="room.visual.complete!=''" color="green">
          <v-icon slot="badge">done</v-icon>
        </v-badge>
      </v-tab>
    </v-tabs>
    <v-tabs-items class="tab-content" v-model="tabs">

      <v-tab-item id="tab-one">

        <form novalidate @submit.prevent="submitRoom('general')">


          <div class="submitContainer" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
            <v-layout >
            <v-btn  class="first-continue-button" :disabled="uploading || !$v.room.general.$anyDirty" type="submit">
              {{$t("uploads.save")}}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" v-if="!unlisted" @click="deleteRoomDialog = true">{{$t("uploads.unlist")}}</v-btn>
            <v-btn color="green" dark v-else @click="relist" :disabled="disabledRelist">{{$t('uploads.finish')}}</v-btn>

            <v-dialog
              v-model="deleteRoomDialog"
              max-width="400px"
            >
              <v-card>
                <v-card-title class="headline">{{$t("uploads.unlistTitle")}}</v-card-title>

                <v-card-text>
                  {{$t("uploads.unlistText")}}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="success"
                    flat="flat"
                    @click="deleteRoomDialog = false"
                  >
                    {{$t("uploads.keep")}}
                  </v-btn>

                  <v-btn
                    color="error"
                    flat="flat"
                    @click="deleteRoom"
                  >
                    {{$t("uploads.unlist")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </v-layout>
            <i class="md-helper-text" v-if="!$v.room.general.$anyDirty">
              {{$t("uploads.waiting")}}
            </i>
            <!-- show loading/submitting thing -->
            <div class="">
              <v-progress-circular indeterminate color="primary" v-if="uploading && !room.general.complete" :size="30" :width="3"></v-progress-circular>
            </div>
          </div>
          <!-- Room Title -->
          <v-text-field v-model="room.general.title" :error-messages="titleErrors" :label="$t('generalInfo.roomTitle')" :hint="$t('generalInfo.titleHint')"  @input="$v.room.general.title.$touch()" @blur="$v.room.general.title.$touch()"></v-text-field>
          <v-text-field v-model="room.general.roomNumber" :error-messages="numberErrors" :label="$t('generalInfo.roomNumber')" :hint="$t('generalInfo.numberHint')"  @input="$v.room.general.roomNumber.$touch()" @blur="$v.room.general.roomNumber.$touch()"></v-text-field>

          <v-menu
           ref="menu2"
           :close-on-content-click="false"
           v-model="menu2"
           :nudge-right="40"
           :return-value.sync="room.general.nextAvailability"
           lazy
           transition="scale-transition"
           offset-y
           full-width
           min-width="290px"
         >
           <v-text-field
            :error-messages="dateErrors"
             slot="activator"
             v-model="room.general.nextAvailability"
             :label="$t('generalInfo.nextAv')"
             prepend-icon="event"
             readonly
             clearable
             @input="$v.room.general.nextAvailability.$touch()"
             @blur="$v.room.general.nextAvailability.$touch()"
           ></v-text-field>
           <v-date-picker  :locale="$vuetify.lang.current" v-model="room.general.nextAvailability" @input="$refs.menu2.save(room.general.nextAvailability)"></v-date-picker>
         </v-menu>
         <v-text-field
           v-model="room.general.successfulBookings"
           :label="$t('generalInfo.successBookings')"
           type="number"
           :hint="$t('generalInfo.successBookingsHint')"
           @input="$v.room.general.successfulBookings.$touch()"
           @blur="$v.room.general.successfulBookings.$touch()"
        ></v-text-field>
        <v-textarea
          v-model="room.general.description"
          :label="$t('generalInfo.description')"
          :hint="$t('generalInfo.descriptionHint')"
          :error-messages="descriptionErrors"
          @input="$v.room.general.successfulBookings.$touch()"
          @blur="$v.room.general.successfulBookings.$touch()"
          ></v-textarea>
          <div class="submitContainer text-xs-right" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
            <v-btn :disabled="uploading || !$v.room.general.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
              {{$t('uploads.save')}}
            </v-btn>
            <i class="md-helper-text" v-if="!$v.room.general.$anyDirty">
              {{$t('uploads.waiting')}}
            </i>
            <!-- show loading/submitting thing -->
            <div class="">
              <v-progress-circular indeterminate color="primary" v-if="uploading && !room.general.complete" :size="30" :width="3"></v-progress-circular>
            </div>
          </div>
        </form>
        </v-tab-item>
        <v-tab-item id="tab-two">
          <form novalidate @submit.prevent="submitRoom('featureList')">


            <div class="submitContainer" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-btn :disabled=" uploading || !$v.room.featureList.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
                {{$t('uploads.save')}}
              </v-btn>
              <i class="md-helper-text" v-if="!$v.room.featureList.$anyDirty">
                {{$t('uploads.waiting')}}
              </i>
              <!-- show loading/submitting thing -->
              <div class="">
                <v-progress-circular indeterminate color="primary" v-if="uploading && !room.featureList.complete" :size="30" :width="3"></v-progress-circular>
              </div>
            </div>
            <v-radio-group :hint="$t('features.bedTypeHint')" :label="$t('features.bedType')" v-model="$v.room.featureList.bedType.$model" :error-messages="bedTypeError">
              <v-radio v-for="(bedType, index) in bedTypes" color="primary" :label="bedType.text" :value="bedType.value" :key="index"></v-radio>
            </v-radio-group>
            <v-radio-group :hint="$t('features.sizeHint')" :error-messages="sizeError" :label="$t('features.size')" v-model="$v.room.featureList.size.$model">
              <v-radio v-for="(size, index) in roomSizes" color="primary" :label="size.text" :value="size.val" :key="index"></v-radio>
            </v-radio-group>
            <v-layout  wrap>
              <v-flex xs12>

              <span class="v-label">{{$t('features.features')}}</span>
            </v-flex>
              <v-flex xs4 v-for="(feature,index) in roomFeatures" :key="index" :value="index">
                <v-checkbox v-model="room.featureList.features" multiple color="primary" :value="index" @change="$v.room.featureList.features.$touch()">
                  <template slot="label">
                    <span><span :class="feature.icon"></span> {{$t('features.'+index)}}</span>
                  </template>

                </v-checkbox>

              </v-flex>
            </v-layout>
            <v-layout  wrap>
              <v-btn flat color="primary" @click="back">{{$t('uploads.back')}}</v-btn>
              <v-spacer></v-spacer>
              <div class="text-xs-right" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
                <v-btn :disabled=" uploading || !$v.room.featureList.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
                  {{$t('uploads.save')}}
                </v-btn>
                <i class="md-helper-text" v-if="!$v.room.featureList.$anyDirty">
                  {{$t('uploads.waiting')}}
                </i>
                <!-- show loading/submitting thing -->
                <div class="">
                  <v-progress-circular indeterminate color="primary" v-if="uploading && !room.featureList.complete" :size="30" :width="3"></v-progress-circular>
                </div>
              </div>
            </v-layout>
          </form>
        </v-tab-item>
        <v-tab-item id="tab-three">
          <form novalidate @submit.prevent="submitRoom('billing')">


            <div class="submitContainer" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-btn :disabled=" uploading || !$v.room.billing.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
                {{$t('uploads.save')}}
              </v-btn>
              <v-btn  v-if="$route.query.roomId!=undefined" :disabled=" uploading  || !$v.room.billing.$anyDirty" color="primary" class="mx-4 first-continue-button section-date md-raised md-primary md-layout-item" @click="exit('billing')">
                {{$t('uploads.finish')}}
              </v-btn>
              <i class="md-helper-text" v-if="!$v.room.billing.$anyDirty">
                {{$t('uploads.waiting')}}
              </i>
              <!-- show loading/submitting thing -->
              <div class="">
                <v-progress-circular indeterminate color="primary" v-if="uploading && !room.billing.complete" :size="30" :width="3"></v-progress-circular>
              </div>
            </div>
            <!-- <v-layout  wrap align-center>
               <v-flex class="pr-4" xs6>
                <v-text-field
                v-model="room.billing.price"
                :label="$t('billing.rent')"
                :error-messages="priceErrors"
                type="number"
                suffix="NT$"
                :hint="$t('billing.rentHint')"
                @input="$v.room.billing.price.$touch()"
                @blur="$v.room.billing.price.$touch()"
                ></v-text-field>

              </v-flex>
              <v-flex xs6 class="text-xs-right pl-4">
              <v-text-field  label="You get" :value="room.billing.price*.97" type="number" suffix="NT$" disabled ></v-text-field>
              </v-flex>
            </v-layout> -->
            <v-layout  wrap>
              <v-flex xs9>
                <v-text-field
                  v-model="room.billing.price"
                  :label="$t('billing.rent')"
                  :error-messages="priceErrors"
                  type="number"
                  suffix="NT$"
                  :hint="$t('billing.rentHint')"
                  @input="$v.room.billing.price.$touch()"
                  @blur="$v.room.billing.price.$touch()"
                  class="pr-4"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 >
                <v-text-field label="You get" :value="youGetVal" type="number" suffix="NT$" disabled ></v-text-field>
              </v-flex>
              <v-flex xs12 v-if="this.user.roleValue>40 || this.user.margin==false">
                <v-text-field v-model="room.billing.llPrice" label="Price to send to landlord" :error-messages="priceErrors" type="number" suffix="NT$" hint="The price the landlord will actually get - Not margin method" @input="$v.room.billing.price.$touch()" @blur="$v.room.billing.price.$touch()"></v-text-field>
              </v-flex>

            </v-layout>

            <v-text-field
              v-model="room.billing.deposit"
              :error-messages="depositErrors"
              :label="$t('billing.deposit')"
              type="number"
              suffix="NT$"
              :hint="$t('billing.depositHint')"
              @input="$v.room.billing.deposit.$touch()"
              @blur="$v.room.billing.deposit.$touch()"
            ></v-text-field>
            <v-switch
              v-if="room.billing.price!=''"
              :label="$t('pricing.flexprice')"
              v-model="$v.room.billing.flexPrice.$model"
              @change="switchFlexPrice"
            ></v-switch>
            <div class="ml-5" v-if="room.billing.flexPrice==true">

              <v-layout wrap>
                <v-flex xs12>
                  <div class="md-helper-text" v-if="!$v.room.billing.$anyDirty">
                    {{$t('pricing.help')}}
                  </div>
                  <v-layout  wrap>
                    <!-- <v-flex xs6 class="title pr-4">
                      {{$t('pricing.rent')}}
                    </v-flex>
                    <v-flex xs6 class="title pl-4">
                      {{$t('pricing.deposit')}}
                    </v-flex> -->
                    <v-flex xs5 class="title pr-4">
                      {{$t('pricing.rent')}}
                    </v-flex>
                    <v-flex xs2 class="title pr-4">
                      You Get
                    </v-flex>
                    <v-flex xs5 class="title pl-4">
                      {{$t('pricing.deposit')}}
                    </v-flex>
                  </v-layout>
                </v-flex>
                </v-layout>
                <v-layout v-if="cLengthVal<3"  wrap>
                  <!-- <v-flex xs6 class="pr-4">
                    <v-text-field  v-model="room.billing.flexPricing.month" :label="$t('pricing.monthly')" type="number" suffix="NT$" :hint="$t('pricing.monthlyHelp')" @change="$v.room.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>
                  <v-flex xs6 class="pl-4">
                    <v-text-field  v-model="room.billing.flexPricing.monthD" :label="$t('pricing.monthly')" type="number" suffix="NT$" :hint="$t('pricing.monthlyHelpD')" @change="$v.room.billing.flexPrice.$touch()" ></v-text-field>
                  </v-flex> -->
                  <v-flex xs5 class="pr-4">
                    <v-text-field  v-model="room.billing.flexPricing.month" :label="$t('pricing.monthly')" type="number" suffix="NT$" :hint="$t('pricing.monthlyHelp')" @change="$v.room.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-4">
                    <v-text-field  :value="Math.floor(room.billing.flexPricing.month*.97)" type="number" suffix="NT$" disabled ></v-text-field>
                  </v-flex>
                  <v-flex xs5 class="pl-4">
                    <v-text-field  v-model="room.billing.flexPricing.monthD" :label="$t('pricing.monthly')" type="number" suffix="NT$" :hint="$t('pricing.monthlyHelpD')" @change="$v.room.billing.flexPrice.$touch()" ></v-text-field>
                  </v-flex>

                </v-layout>

                <v-layout v-if="cLengthVal<5"  wrap>
                  <v-flex xs5 class="pr-4">
                    <v-text-field  v-model="room.billing.flexPricing.three" :label="$t('pricing.three')" type="number" suffix="NT$" :hint="$t('pricing.threeHelp')" @change="$v.room.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-4">
                    <v-text-field  :value="Math.floor(room.billing.flexPricing.three*.97)" type="number" suffix="NT$" disabled ></v-text-field>
                  </v-flex>

                  <v-flex xs5 class="pl-4">
                    <v-text-field  v-model="room.billing.flexPricing.threeD" :label="$t('pricing.three')" type="number" suffix="NT$" :hint="$t('pricing.threeHelpD')" @change="$v.room.billing.flexPrice.$touch()" ></v-text-field>
                  </v-flex>

                </v-layout>

                <v-layout v-if="cLengthVal<12"  wrap>
                  <v-flex xs5 class="pr-4">
                    <v-text-field  v-model="room.billing.flexPricing.five" :label="$t('pricing.five')" type="number" suffix="NT$" :hint="$t('pricing.fiveHelp')" @change="$v.room.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-4">
                    <v-text-field  :value="Math.floor(room.billing.flexPricing.five*.97)" type="number" suffix="NT$" disabled ></v-text-field>
                  </v-flex>

                  <v-flex xs5 class="pl-4">
                    <v-text-field  v-model="room.billing.flexPricing.fiveD" :label="$t('pricing.five')" type="number" suffix="NT$" :hint="$t('pricing.fiveHelpD')" @change="$v.room.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>

                </v-layout>

                <v-layout  wrap>
                  <v-flex xs5 class="pr-4">
                    <v-text-field v-model="room.billing.flexPricing.year" :label="$t('pricing.year')" type="number" suffix="NT$" :hint="$t('pricing.yearHelp')" @change="$v.room.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-4">
                    <v-text-field  :value="Math.floor(room.billing.flexPricing.year*.97)" type="number" suffix="NT$" disabled ></v-text-field>
                  </v-flex>

                  <v-flex xs5 class="pl-4">
                    <v-text-field v-model="room.billing.flexPricing.yearD" :label="$t('pricing.year')" type="number" suffix="NT$" :hint="$t('pricing.yearHelpD')" @change="$v.room.billing.flexPrice.$touch()" ></v-text-field>
                  </v-flex>

                </v-layout>
            </div>
            <v-switch color="primary" :error-messages="couplesAllowedError" :label="$t('billing.couplesAllowed')" v-model="$v.room.billing.couplesAllowed.$model"></v-switch>
            <v-text-field
              v-if="room.billing.couplesAllowed"
              v-model="room.billing.couplesExtraUtilities"
              :label="$t('billing.couplesExtraUtilities')"
              type="number"
              suffix="NT$"
              :error-messages="couplesExtraUtilitiesError"
              :hint="$t('billing.couplesExtraUtilitiesHint')"
              @input="$v.room.billing.couplesExtraUtilities.$touch()"
              @blur="$v.room.billing.couplesExtraUtilities.$touch()"
            ></v-text-field>

            <v-layout  wrap>
              <v-btn flat color="primary" @click="back">{{$t('uploads.back')}}</v-btn>
              <v-spacer></v-spacer>

            <div class="submitContainer text-xs-right" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-btn  v-if="$route.query.roomId!=undefined" :disabled=" uploading  || !$v.room.billing.$anyDirty" color="primary" class="mx-4 first-continue-button section-date md-raised md-primary md-layout-item" @click="exit('billing')">
                {{$t('uploads.finish')}}
              </v-btn>
              <v-btn :disabled=" uploading || !$v.room.billing.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
                {{$t('uploads.save')}}
              </v-btn>
              <i class="md-helper-text" v-if="!$v.room.billing.$anyDirty">
                {{$t('uploads.waiting')}}
              </i>
              <!-- show loading/submitting thing -->
              <div class="">
                <v-progress-circular indeterminate color="primary" v-if="uploading && !room.billing.complete" :size="30" :width="3"></v-progress-circular>
              </div>
            </div>
            </v-layout>
          </form>
        </v-tab-item>
        <v-tab-item id="tab-four">
        <div class="display-1 text-xs-center pa-2">{{$t('generalInfo.tenantsText')}}</div>
            <AddTenant @addNewTenant="addNewTenant"></AddTenant>
          <br>
          <hr>
          <div class="title pa-3">
            <strong >{{$t('generalInfo.currentTenants')}}</strong>
          </div>

          <showTenant v-for="(tenant,index) in room.tenants.tenants" :key="index" :tenant="tenant" :index="index" @removeTenant="removeTenant"></showTenant>
          <v-layout  wrap>
            <v-btn flat color="primary" @click="back">{{$t('uploads.back')}}</v-btn>

            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="tabs='tab-five'">{{$t('uploads.save')}}</v-btn>
          </v-layout>


          </v-tab-item>
          <v-tab-item id="tab-five">
            <div class="title">{{$t('visual.showTenants')}}</div>

            <Dropzone id="photos" @onUpload="uploadPhotos" @onSorted="sortedPhotos" :photos="room.visual.photos" :tag="['Room']" :landlordId="user.id" @addPhoto='addPhoto($event,"Room")'>
            </Dropzone>
            <v-layout  wrap>
              <v-btn flat color="primary" @click="back">{{$t('uploads.back')}}</v-btn>

              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="finish">{{$t('uploads.finish')}}</v-btn>
            </v-layout>
          </v-tab-item>
      </v-tabs-items>

  </v-container>
  <v-snackbar
    v-model="showSnackbar"
    color="success"
    :timeout="6000"

  >
    {{snackbarMessage}}
    <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
  </v-snackbar>

</div>
</template>
<script>

import ShowTenant from './ShowTenant.vue'
import {
  mapGetters
} from 'vuex'
import moment from 'moment'
import Loading from '../shared/Loading.vue'
import * as types from '../../store/types'
import AddTenant from './AddTenant.vue'
import Bathroom from './Bathroom.vue'
import Dropzone from '../Dropzone.vue'
import axios from 'axios'
import draggable from 'vuedraggable'
import AWSSES from '../../aws-ses'
import { required, minLength} from 'vuelidate/lib/validators'



export default {
  name: "",
  data: () => ({
    apartment:{},
    deleteRoomDialog:false,
    menu2:false,
    disabledRelist:false,
    tabs:"tab-one",
    initialLoad: true,
    roomDraft:false,
    trustVal:0,
    edit: false,
    newRoom: false,
    myDialog: false,
    landlordId:"",
    cLengthVal:12,
    addedTenants: [],
    myNewTenants: [],
    header: {
      active: 'two',
      done: true,
    },
    roomServer: {},
    room: {
      lastModified: new Date(),
      draft:"true",
      apartmentId: "",
      dateAdded: "",
      type: "Room",
      general :{
        complete: false,
        title: "",
        roomNumber: "",
        nextAvailability: '',
        successfulBookings: 2,
        description: "",
      },
      featureList: {
        complete: false,
        bedType: '',
        size: '',
        // features: ['ac', 'mattress', 'bedsheets', 'chair', 'desk', 'pillow', ],
        features: []
      },
      billing:{
        flexPrice:false,
        complete: false,
        deposit: "",
        price: "",
        llPrice:"",
        couplesAllowed: false,
        couplesExtraUtilities: 0,
      },
      tenants:{
        complete:false,
        tentants: []
      },
      visual:{
        complete:false,
        photos: []
      },
    },
    uploadedPhotos:{'photos':[]},
    apartmentSummaryId: "",
    roomId: "",
    index: "",

    uploading: false,
    complete: false,
    showSnackbar: false,
    snackbarMessage: "",

    errorMessage: '',

    roomFeatures: {
      privateBathroom: {
        value: false,
        icon: 'MRAIcon_private_bathroom',
        name: 'Private Bathroom'
      },
      ac: {
        value: true,
        icon: 'MRAIcon_air_conditioning',
        name: 'A/C'
      },
      fan: {
        value: false,
        icon: 'MRAIcon_fan',
        name: 'Fan'
      },
      outsideWindow: {
        value: true,
        icon: 'MRAIcon_outside_window',
        name: 'Outside Window'
      },

      innerWindow: {
        value: false,
        icon: 'MRAIcon_inner_window',
        name: 'Inner Window'
      },
      curtains: {
        value: false,
        icon: 'MRAIcon_curtains',
        name: 'Curtains'
      },
      mattress: {
        value: true,
        icon: 'MRAIcon_mattress',
        name: 'Mattress'
      },
      pillow: {
        value: true,
        icon: 'MRAIcon_pillow',
        name: 'Pillow'
      },
      sheets: {
        value: true,
        icon: 'MRAIcon_sheets',
        name: 'Sheets'
      },
      desk: {
        value: true,
        icon: 'MRAIcon_desk',
        name: 'Desk'
      },
      chair: {
        value: true,
        icon: 'MRAIcon_chair',
        name: 'Chair'
      },
      wardrobe: {
        value: true,
        icon: 'MRAIcon_wardrobe',
        name: 'Wardrobe'
      },
      openHanger: {
        value: false,
        icon: 'MRAIcon_open_hanger',
        name: 'Open Hanger'
      },
      shelve: {
        value: false,
        icon: 'MRAIcon_shelves',
        name: 'Shelve'
      },
    },
    admin: {
      email: 'hello@myroomabroad.com',
      name: 'admin'
    }
  }),
  validations: {
    room: {
      general: {
        title: {
          required,
          minLength: minLength(4)
        },
        roomNumber: {
          required,
          nonChinese(name){
            return(/^[\x20-\x7E]*$/.test(name))
          }

        },
        nextAvailability: {
          required
        },
        successfulBookings: {
        },
        description: {
          required
        }
      },
      featureList: {
        bedType: {
          required
        },
        size: {
          required
        },
        features:{

        }
      },
      billing:{
        deposit: {
          required
        },
        flexPrice:{
        },
        price: {
          required
        },
        couplesAllowed: {
          required
        },
        couplesExtraUtilities:{
          required
        },
      },

    },
  },
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 150,
        behavior: "smooth"
      })
    },
    checkllPrice(){
      if (this.room.billing.llPrice!="" && this.room.billing.llPrice!=undefined){
        if (parseInt(this.room.billing.llPrice)>parseInt(this.room.billing.price)*.97){
          if (this.user.roleValue>50){
            alert("Don't forget to decrease the Price Sent to landlord!")
          } else {
            let admin={
              name:"Admin",
              email:"hello@myroomabroad.com"
            }
            let info={
              url:'https://myroomabroad.com'+this.$route.fullPath,
              room:this.room.general.fullRoomNumber,
            }
            AWSSES.emailingInfo(admin, info, 'ALLPriceTooHigh')
            console.log('sendEmail');
          }
        }
      }
    },
    setContractLengthVal(){
      switch (this.apartment.contractDetails.contractLength) {
        case '1 month':
          this.cLengthVal=1
          break;
        case '3 months':
          this.cLengthVal=3
          break;
        case '5 months':
          this.cLengthVal=5
          break;
        case '12 months':
          this.cLengthVal=12
          break;
        default:
          this.cLengthVal=12
      }
    },
    switchFlexPrice(){
      this.setContractLengthVal()
      this.room.billing.flexPricing={}
      if (this.room.billing.flexPrice==true){
        this.room.billing.flexPricing.month=Math.floor(1.3*this.room.billing.price)
        this.room.billing.flexPricing.three=Math.floor(1.2*this.room.billing.price)
        this.room.billing.flexPricing.five= Math.floor(1.1*this.room.billing.price)
        this.room.billing.flexPricing.year=this.room.billing.price
        this.room.billing.flexPricing.monthD=5000
        this.room.billing.flexPricing.threeD=this.room.billing.flexPricing.three
        this.room.billing.flexPricing.fiveD= this.room.billing.flexPricing.five*2
        this.room.billing.flexPricing.yearD=this.room.billing.flexPricing.year*2
      }
    },    formattedDate(dateString) {
      if (moment(dateString).isValid()) {
        return moment(dateString).format('YYYY-MM-DD')
      }
    },
    back(){
      switch (this.tabs) {
        case 'tab-five':
          this.tabs='tab-four'
          this.scrollTo()
          break
        case 'tab-four':
          this.tabs='tab-three'
          this.scrollTo()
          break;
        case 'tab-three':
          this.tabs="tab-two"
          this.scrollTo()
          break
        case 'tab-two':
          this.tabs="tab-one"
          this.scrollTo()
          break
      }
    },


    /**************
      Tennants Section
    **************/

    addNewTenant(payload) {
      // console.log('payload', payload);
      if(this.room.tenants['tenants'] === undefined){
        this.room.tenants['tenants'] = [];
      }

      this.room.tenants['tenants'].push(payload.tenant)
      this.room.tenants['tenants']['complete'] = true;


      this.updateRoom('tenants', this.room.tenants);

    },
    removeTenant(index) {
      this.room.tenants.tenants.splice(index, 1);
      this.updateRoom('tenants', this.room.tenants.tentants);


    },
    noPreviousTenants(){
      this.updateRoom('tenants', {'unLeased': true});
    },






    /**************
      Photos Section
    **************/

    uploadPhotos(x){
      // console.log('photos uploaded -->', x);
      if (!this.room.visual.hasOwnProperty('photos')) {
        this.room.visual.photos = []
      }
      this.room.visual.photos.push(x);
      this.updateRoom('visual', this.room.visual);
    },
    sortedPhotos(x){
      console.log('photos sorted -->', x);
      this.room.visual['photos'] = x;
      this.updateRoom('visual', this.room.visual);
    },

    /*****
    Validation stuffs
    *******/

    touchField(section){
      // console.log('touch field...', section, field);
      this.$v.room[section].$touch();
    },

    //setting the class on the field to show errors
    getValidationClass (section, fieldName) {
      // console.log('get validation class');
      // const vsection = this.$v.room[section]
      const field = this.$v.room[section][fieldName]
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    //validating the section, if valid then submit to server
    validateSection(section) {
      // console.log('validate section');
      this.$v.room[section].$touch()
      if (!this.$v.room[section].$invalid || !this.$v.room[section].$anyError) {
        return true;
      }
      else{
        return false;
      }
    },


    /**************
      Submit Section
    **************/

    submitRoom(section) {
      this.validateSection(section) ? this.updateRoom(section, this.room[section]) : null;
    },
    checkOtherForms(){
      // console.log('check other forms');
      if (this.room.general.complete==true && this.room.featureList.complete==true && this.room.billing.complete==true){
        if (this.room.visual.photos && this.room.visual.photos.length>0){
          this.roomDraft=false
        }
      } else {
        this.roomDraft=true
      }

    },
    //actual function to submit room after validation
    updateRoom(formName, formData) {

        // console.log('updateRoom');
        let vThis = this;
        let obj = {}
        formData['complete'] = true;
        obj[formName] = formData;
        obj['lastModified']=new Date()
        if (formName=="billing"){
          this.checkllPrice()
        }
        vThis.uploading = true
        vThis.room[formName].complete=true
        // console.log('checking');
        this.checkOtherForms()
        let urlMethod = 'patch';
        let urlFormat = `/rooms/${vThis.roomId }.json/?auth=${vThis.$store.state.auth.idToken}`;
        if(formName == "general"){
          // console.log('fulllRoomNumber');
          obj.general['fullRoomNumber']=vThis.apartmentName+" "+vThis.room.general.roomNumber
        }
        obj['trustVal']=vThis.trustVal
        if(this.newRoom == true){
          urlMethod = 'post'
          urlFormat = `/rooms.json/?auth=${vThis.$store.state.auth.idToken}`
          obj['apartmentId'] = vThis.apartmentId;
          obj['dateAdded']= new Date()
        }
        obj['lastModified']= new Date()
        if (this.roomDraft==false){
          obj['draft']=false
        } else {
          obj['draft']=true
        }
        // upload the form data per section
        axios({
          method: urlMethod,
          url: urlFormat,
          data: obj
        })
        .then(res => {
          // console.log('Room update successfully -->', res);

          //add the room id and name to the parent Apartment
          if(vThis.newRoom == true || vThis.$v.room.general.title.$dirty){
            let vData = {'rooms': {}};
            if(vThis.newRoom==true){
              vThis.roomId=res.data.name
            }
            axios.get('/apartments/'+vThis.apartmentId+'/rooms.json')
            .then(res => {

              // console.log('apartment.rooms:', res.data)
              if (res.data!=null){
                vData.rooms=res.data
              }
              vData.rooms[vThis.roomId] = {'number': vThis.room.general.roomNumber}
              vThis.updateParentApt(vData, vThis.roomId)
            })
            .catch(err => {
              console.log('error:' , err);
            })
          }
          else{
            vThis.cleanUpPosting();
          }
          })
        .catch(err => {
          console.log('room update failed',err)
          // console.log(err)
        })
        .finally(()=>{
          this.nextTab()
        })


    },
    //update the parent apt wth id and name
    updateParentApt(formData, roomId){
      let vThis = this;
      // upload the form data per section
      formData['lastModified']=new Date()
      axios({
        method: 'patch',
        url: `/apartments/${vThis.apartmentId}.json` + '?auth=' + vThis.$store.state.auth.idToken,
        data: formData
      })
      .then(() => {
        // console.log('Parent updated successfully -->', res);
        //add the room id and name to the parent Apartment
          vThis.newRoom = false;
          vThis.cleanUpPosting();
          vThis.$router.push({ name: 'room', params: {apartmentId: vThis.apartmentId}, query:{roomId:roomId}})


      })
      .catch(err => {
        // console.log('room update failed')
        console.log(err)
      });

    },
    //clean up functions for calls
    cleanUpPosting(){
      this.snackbarMessage = this.$t('uploads.snackbarMessage')
      this.showSnackbar = true;
      this.uploading = false;
      this.complete = true;
      //closes the snackbar
      setTimeout(function(){
           this.complete = false;
       }, 4000);
       this.$v.$reset();
    },


    /**************
      Get DAtA Section
    **************/
    getRoom(){
      console.log('[getRoom]');
      const vThis = this;
      axios.get('/rooms/' + this.roomId + '.json')
      .then(res => {

            // console.log('room exist and is downloaded......', res)
            //set baseline data for comparing
            vThis.roomServer = res.data;
            vThis.roomDraft=res.data.draft
            // let sections = ['general', 'featureList', 'billing', 'visual', 'tenants']
            //set fields
            if(res.data !== null){
              vThis.trustVal=res.data.trustVal
              vThis.room['general'] = res.data.general !== undefined ? res.data.general : vThis.room.general;
              vThis.room['featureList'] = res.data.featureList !== undefined ? res.data.featureList : vThis.room.featureList;
              vThis.room['billing'] = res.data.billing !== undefined ? res.data.billing : vThis.room.billing;
              vThis.room['visual'] = res.data.visual !== undefined ? res.data.visual : vThis.room.visual;
              vThis.room['tenants'] = res.data.tenants !== undefined ? res.data.tenants : vThis.room.tenants;
              vThis.edit = true
              if (vThis.room.billing.flexPrice==true) {
                this.setContractLengthVal()
              }
              if (vThis.room.general.nextAvailability!=undefined){
                vThis.room.general.nextAvailability=moment(vThis.room.general.nextAvailability).format("YYYY-MM-DD")
              }

            }

          vThis.initialLoad = false;
        })
        .catch(err=>{
          console.log('error: ',err);
        })

    },
    relist(){
      this.disabledRelist=true
      axios.patch('rooms/'+this.roomId+'.json/?auth='+this.$store.state.auth.idToken,{draft:false})
      .then(()=>{
        if (this.landlordId!=this.user.id){
          this.$router.push({
            name:'adminLandlordDashboard',
            params:{
              id:this.landlordId
            }
          })
        } else {
          this.$router.push({
            name: 'LandlordDashboard'
          })
        }
      })
      .catch(err=>{
        console.log('error: ',err);
        this.disabledRelist=false
      })

    },
    nextTab(){
      console.log('do nextTab');
      switch (this.tabs) {
        case 'tab-one':
        this.tabs='tab-two'
        this.scrollTo()
        break;
        case 'tab-two':
        this.tabs="tab-three"
        this.scrollTo()
        break
        case 'tab-three':
        this.tabs="tab-four"
        this.scrollTo()
        break
      }
    },
    exit(item){
      this.submitRoom(item)
      if (this.landlordId!=this.user.id){
        this.$router.push({
          name:'adminLandlordDashboard',
          params:{
            id:this.landlordId
          }
        })
      } else {
        this.$router.push({
          name: 'LandlordDashboard'
        })
      }
    },
    finish() {
      if (this.tabs=='tab-four'){
        this.tabs='tab-five'
      } else {

      console.log('finish')
      if (this.landlordId!=this.user.id){
        this.$router.push({
          name:'adminLandlordDashboard',
          params:{
            id:this.landlordId
          }
        })
      } else {
        this.$router.push({
          name: 'LandlordDashboard'
        })
      }
    }
  },
  deleteRoom(){
    // console.log('delete the room');
    let vThis=this
    if (vThis.roomId!=undefined && vThis.roomId!=null && vThis.roomId!=""){
      axios.patch('rooms/'+vThis.roomId+'.json/?auth='+vThis.$store.state.auth.idToken,{draft:true})
      .then(()=>{
        this.$router.push({name:'LandlordDashboard'})
      })
      .catch(err=>{
        console.log('error: ',err);
      })

    }

  }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    youGetVal(){
      if (this.room.billing!=undefined && this.room.billing.llPrice!="" &&this.room.billing.llPrice!=undefined){
        return this.room.billing.llPrice
      } else {
        return Math.floor(parseInt(this.room.billing.price)*.97)
      }
    },
    unlisted:function(){
      let finished= false
      if (this.room.general.complete==true && this.room.featureList.complete==true && this.room.billing.complete==true){
        if (this.room.visual.photos && this.room.visual.photos.length>0){
          finished=true
        }
      }
      if (finished && this.roomDraft){
        return true
      } else {
        return false
      }

    },

    roomSizes() {
      let t=[{
          text:'<10 m² (< 3 '+this.$t('uploads.ping')+')',
          val:'small',
      },
      {
        text: '10-15 m² (3-5 '+this.$t('uploads.ping')+')',
        val:'average'
      },
      {
        text:'>15 m² (> 5 '+this.$t('uploads.ping')+')',
        val:'large'
      }]
      return t
    } ,
    bedTypes() {
      let t = ['None', 'Single', 'Double']

      if (this != undefined) {
        t = [{
            value: 'None',
            text: this.$t('features.bedTypeOptionNone')
          },
          {
            value: 'Single',
            text: this.$t('features.bedTypeOptionSingle')
          },
          {
            value: 'Double',
            text: this.$t('features.bedTypeOptionDouble')
          }
        ]
      }
      return t
    },

    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    titleErrors() {
      const errors = []
      if (!this.$v.room.general.title.$dirty) return errors
      !this.$v.room.general.title.required && errors.push(this.$t('generalInfo.titleHint'))
      !this.$v.room.general.title.minLength && errors.push('Title must have at least '+this.$v.room.general.title.$params.minLength.min+" characters")
      return errors
    },
    numberErrors() {
      const errors=[]
      if (!this.$v.room.general.roomNumber.$dirty) return errors
      !this.$v.room.general.roomNumber.required && errors.push(this.$t('generalInfo.numberHint'))
      !this.$v.room.general.roomNumber.nonChinese && errors.push(this.$t('auth.userNameRegexErr'))
      return errors
    },
    dateErrors() {
      const errors=[]
      if (!this.$v.room.general.nextAvailability.$dirty) return errors
      !this.$v.room.general.nextAvailability.required && errors.push('Please provide an availability date')
      return errors
    },
    descriptionErrors(){
      const errors=[]
      if (!this.$v.room.general.description.$dirty) return errors
      !this.$v.room.general.description.required && errors.push(this.$t('generalInfo.descriptionHint'))
      return errors
    },
    priceErrors() {
      const errors=[]
      if (!this.$v.room.billing.price.$dirty) return errors
      !this.$v.room.billing.price.required && errors.push(this.$t('billing.rentHint'))
      return errors
    },
    depositErrors() {
      const errors=[]
      if (!this.$v.room.billing.deposit.$dirty) return errors
      !this.$v.room.billing.deposit.required && errors.push(this.$t('billing.depositHint'))
      return errors
    },
    bedTypeError(){
      const errors=[]
      if (!this.$v.room.featureList.bedType.$dirty) return errors
      !this.$v.room.featureList.bedType.required && errors.push(this.$t('features.bedTypeHint'))
      return errors
    },
    sizeError(){
      const errors=[]
      if (!this.$v.room.featureList.size.$dirty) return errors
      !this.$v.room.featureList.size.required && errors.push(this.$t('features.sizeHint'))
      return errors
    },
    couplesAllowedError(){
      const errors=[]
      if (!this.$v.room.billing.couplesAllowed.$dirty) return errors
      !this.$v.room.billing.couplesAllowed.required && errors.push('Please switch click the switch twice. Something weird happened')
      return errors
    },
    couplesExtraUtilitiesError(){
      const errors=[]
      if (!this.$v.room.billing.couplesExtraUtilities.$dirty) return errors
      !this.$v.room.billing.couplesExtraUtilities.required && errors.push(this.$t('billing.couplesExtraUtilitiesHint'))
      return errors
    }
  },
  created() {
    const vThis = this;
    // console.log('created room id', vThis.$route.query.roomId);
    if (this.$route.query.edit=="billing"){
      this.tabs='tab-three'
    }

    // set the roomID
    vThis.apartmentId = vThis.$route.params.apartmentId
    axios.get('apartments/'+this.$route.params.apartmentId+'.json')
    .then(res=>{
      this.apartment=res.data
      this.apartmentName=res.data.general.name
      if (res.data.trustVal!=undefined){
        this.trustVal = res.data.trustVal
      } else if (res.data.llTrustVal!=undefined){
        this.trustVal = res.data.trustVal
      }
      this.landlordId=res.data.general.landlordId
      if (vThis.$route.query.roomId != undefined) {
        // console.log('downloading room data...............');
        vThis.roomId = vThis.$route.query.roomId
        vThis.getRoom();
      }
      else{
        vThis.newRoom = true
        vThis.draft=true
        vThis.initialLoad = false;
      }
    })
    .catch(err=>{
      console.log('error: ',err);
    })


    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

    // console.log('this.$route.query',vThis.$route.query.index, vThis.$route.query.roomId);
    // console.log('this.roomId != undefined && vThis.user.id', vThis.$route.query.roomId != undefined , this.user);

    // else if (vThis.$route.query.index !== undefined) {
    //   console.log('new room----------->');
    //   vThis.index = vThis.$route.query.index
    //   vThis.newRoom = true
    //     vThis.initialLoad = false;
    // }
    // else if (this.$route.query.index === "0" || this.$route.query.index === 0) {
    //   this.index = 0
    //   this.newRoom = true
    //     vThis.initialLoad = false;
    // }



  },

  components: {
    Bathroom,
    Dropzone,
    Loading,
    draggable,
    AddTenant,
    ShowTenant,
  }
}
</script>


<style lang="scss" scoped>


.size-group {
    display: inline-block;
}

.draggable-image-div {
    width: 80px;
    height: 80px;
    position: relative;

}
.draggable-image-div :hover {
    cursor: pointer;
}
.for-div {
    height: 80px;
    width: 80px;
    float: left;
}
.drag-image {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    margin: auto;
    width: 80px;
    height: 80px;
    padding: 5px;
    object-fit: cover;
}
.close {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 0;
    display: block;
    float: right;
    font-size: 20px;
}

.icon-delete-btn :hover {
    cursor: pointer;
    background-color: rgba(54, 183, 254, 1);
}
.tab-content{
  margin-top:1em;
}
.draggable {
    margin-bottom: 50px;
}
.first-continue-button {
    margin-top: 1rem;
    margin-bottom: .5rem;
}



</style>

<!-- Global fix for auto generated components like Steppers -->
<style lang="scss" scoped>

// .{
//   background: #00c853!important;
// }

.roomContainer{

  .md-snackbar{
    background: #00c853!important;
  }

  .initialLoadContainer{
    padding: 30vh 0;

    .md-progress-spinner{
      margin:0 30%;
    }
  }



  .section{
    margin-bottom: 20px;
  }

  .features {
      text-align: left;
      .md-checkbox .md-checkbox-label{
        top: 0!important;
      }
  }

  form{
    label{
      font-weight: bold;
    }
    .label{
      font-weight: bold;
      display: block;
      font-size: 12px;
    }
    .vertical-labels{
      flex-direction: column;
      // BUG: weird spacing issue that cuts off left border
      padding-left: 1px;
    }
    .md-helper-text{
      color: #bbb;
    }
    .md-field{
      margin-bottom: 0;
      &.md-invalid{
        margin-bottom: 24px;
      }
    }
    .md-helper-text{
      display: block;
      margin-bottom: 18px;
    }
  }


  .submitContainer{
    padding: 0 0 20px 0;
    &.mobile{
      width: 100%;
      padding: 10px 0 30px;
      .v-btn{
        display: block;
        width: 100%;
      }
    }
    .v-btn{
      margin: 0 auto;
    }
  }

  .md-tabs{
    .md-tabs-navigation{
      background: #eee!important;
      .badge{
        position: absolute;
        background: #00c853;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        top: 34%;
        right: 30%;
      }
    }
    .md-tab{
      padding: 20px 2px 40px 2px;
    }

    &.desktop{
      min-height: 75vh;
      .md-tabs-content{
        min-height: 75vh;
      }
      .md-field{
        margin-bottom: 30px;
      }
    }

  }


}




</style>
