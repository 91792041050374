<template>
<div class="studioContainer">
  <Navbar></Navbar>
  <Loading v-if="initialLoad"></Loading>

  <v-layout  wrap justify-center v-else>
    <v-flex md9 xs11>
      <h1>
        {{$t('uploads.studio')}}

      </h1>
      <v-btn color="primary" flat href="https://youtube.com/watch?v=v91RkrULR9I" target="blank">{{$t('newListing.howToAddStudio')}}</v-btn>
      <v-btn color="primary" flat :href="'/studioDetails/'+studioId" target="blank" v-if="studioDraft==false && user.roleValue>40">See Listing</v-btn>
      <v-tabs centered color="primary" show-arrows dark icons-and-text v-model="tabs">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab href="#tab-one">
          {{$t('uploads.info')}}
          <v-icon color="#ffffff">domain</v-icon>
          <v-badge v-if="studio.general.complete" color="green">
            <v-icon slot="badge" color="#ffffff">done</v-icon>
          </v-badge>
        </v-tab>
        <v-tab href="#tab-two">
          {{$t('uploads.features')}}
          <v-icon>hotel</v-icon>
          <v-badge v-if="studio.featureList.complete" color="green">
            <v-icon slot="badge">done</v-icon>
          </v-badge>
        </v-tab>
        <v-tab href="#tab-three">
          {{$t('uploads.contract')}}
          <v-icon>description</v-icon>
          <v-badge v-if="studio.contractDetails.complete" color="green">
            <v-icon slot="badge">done</v-icon>
          </v-badge>
        </v-tab>

        <v-tab href="#tab-four">
          {{$t('uploads.billing')}}
          <v-icon>attach_money</v-icon>
          <v-badge v-if="studio.billing.complete" color="green">
            <v-icon slot="badge">done</v-icon>
          </v-badge>
        </v-tab>
        <v-tab href="#tab-five">
          {{$t('uploads.tenants')}}

          <v-icon>people</v-icon>
          <v-badge v-if="studio.tenants.complete" color="green">
            <v-icon slot="badge">done</v-icon>
          </v-badge>
        </v-tab>

        <v-tab href="#tab-six">
          {{$t('uploads.photos')}}
          <v-icon>image</v-icon>
          <v-badge v-if="studio.visual.complete" color="green">
            <v-icon slot="badge">done</v-icon>
          </v-badge>
        </v-tab>

      </v-tabs>
      <v-tabs-items class="tab-content" v-model="tabs">

        <v-tab-item id="tab-one">

          <form novalidate @submit.prevent="submitApt('general')">


            <div class="submitContainer md-size-100 md-layout-item" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-layout  wrap>

                <v-btn :disabled=" uploading || !$v.studio.general.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
                  {{$t('uploads.save')}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" v-if="!unlisted" @click="deleteStudioDialog = true">
                  {{$t('uploads.unlist')}}
                </v-btn>
                <v-btn color="green" dark v-else @click="relist" :disabled="disabledRelist">{{$t('uploads.finish')}}</v-btn>

                <v-dialog v-model="deleteStudioDialog" max-width="400px">
                  <v-card>
                    <v-card-title class="headline">
                      {{$t('uploads.unlistTitle')}}
                    </v-card-title>
                    <v-card-text>
                      {{$t('uploads.unlistText')}}

                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn color="success" flat="flat" @click="deleteStudioDialog = false">
                        {{$t('uploads.keep')}}

                      </v-btn>

                      <v-btn color="error" flat="flat" @click="deleteStudio">
                        {{$t('uploads.unlist')}}

                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </v-layout>

              <i class="md-helper-text" v-if="!$v.studio.general.$anyDirty">
                {{$t('uploads.waiting')}}
              </i>
              <!-- show loading/submitting thing -->
              <div class="">
                <v-progress-circular indeterminate color="primary" v-if="uploading && !studio.general.complete" :size="30" :width="3"></v-progress-circular>
              </div>
            </div>

            <!-- Studio Title  -->
            <v-text-field v-model="studio.general.title" :error-messages="titleErrors" :label="$t('generalInfo.studioTitle')" :hint="$t('generalInfo.titleHint')" @input="$v.studio.general.title.$touch()" @blur="$v.studio.general.title.$touch()"></v-text-field>

            <!-- studio NAME -->
            <v-text-field v-model="studio.general.name" :error-messages="nameErrors" :label="$t('generalInfo.studioName')" :hint="$t('generalInfo.nameHint')" required @input="$v.studio.general.name.$touch()" @blur="$v.studio.general.name.$touch()"></v-text-field>
            <!-- Availability section -->
            <v-menu ref="menu2" :close-on-content-click="false" v-model="menu2" :nudge-right="40" :return-value.sync="studio.general.nextAvailability" lazy transition="scale-transition" offset-y full-width min-width="290px">
              <v-text-field :error-messages="dateErrors" slot="activator" v-model="studio.general.nextAvailability" :label="$t('generalInfo.nextAv')" prepend-icon="event" readonly clearable @input="$v.studio.general.nextAvailability.$touch()" @blur="$v.studio.general.nextAvailability.$touch()"></v-text-field>
              <v-date-picker :locale="$vuetify.lang.current" v-model="studio.general.nextAvailability" @input="$refs.menu2.save(studio.general.nextAvailability)"></v-date-picker>
            </v-menu>
            <v-text-field v-model="studio.general.successfulBookings" :label="$t('generalInfo.successBookings')" type="number" :hint="$t('generalInfo.successBookingsHint')" @input="$v.studio.general.successfulBookings.$touch()" @blur="$v.studio.general.successfulBookings.$touch()"></v-text-field>

            <!-- TEXT DESCRIPTION -->

            <v-textarea v-model="studio.general.description" :error-messages="descriptionErrors" :label="$t('generalInfo.description')" :hint="$t('generalInfo.descriptionHint')" required :counter="500" @input="$v.studio.general.description.$touch()"
              @blur="$v.studio.general.description.$touch()"></v-textarea>

            <!-- LandlordSelect for ADMIN ONLY -->
            <v-autocomplete :loading="loading" :items="landlords" v-model="studio.general.landlordId" hide-details clearable item-text="userName" item-value="id" v-if="user.roleValue>20" label="Who's the landlord?">
              <template slot="no-data">
                <v-list-tile>
                  <v-list-tile-title>
                    No landlord found!
                  </v-list-tile-title>
                </v-list-tile>
              </template>

              <template slot="selection" slot-scope="{item, selected}">
                <v-chip :selected="selected" color="primary" class="white--text">
                  <span v-text="item.userName +' ('+item.email+')'"></span>
                </v-chip>

              </template>
              <template slot="item" slot-scope="{ item, tile }">
                <v-list-tile-avatar color="indigo" class="headline font-weight-light white--text">
                  <!-- {{ item.userName.charAt(0) }} -->
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title v-text="item.userName"></v-list-tile-title>
                  <v-list-tile-sub-title v-text="item.email"></v-list-tile-sub-title>
                </v-list-tile-content>
              </template>
            </v-autocomplete>
            <!-- LOCATION -->
            <v-text-field :label="$t('generalInfo.floor')" :error-messages="floorError" type="number" v-model="studio.general.floor" :hint="$t('generalInfo.floor')" @input="$v.studio.general.floor.$touch()" @blur="$v.studio.general.floor.$touch()"></v-text-field>
            <div class="md-form-group autocomplete-group md-layout-item md-size-100">
              <UploadOSM :cLocation="studio.general.location" :general="studio.general" :user="user" :errors="locationErrors" @setLocation="setLocation"></UploadOSM>
            </div>
            <v-layout  wrap>

              <v-spacer></v-spacer>
              <div class="text-xs-right">

                <v-btn :disabled=" uploading || !$v.studio.general.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
                  {{$t('uploads.save')}}
                </v-btn>
                <i class="md-helper-text" v-if="!$v.studio.general.$anyDirty">
                  {{$t('uploads.waiting')}}
                </i>
                <!-- show loading/submitting thing -->
                <div class="">
                  <v-progress-circular indeterminate color="primary" v-if="uploading && !studio.general.complete" :size="30" :width="3"></v-progress-circular>
                </div>
              </div>

            </v-layout>
          </form>

        </v-tab-item>
        <v-tab-item id="tab-two">


          <form novalidate @submit.prevent="submitApt('featureList')">
            <div class="submitContainer md-size-100 md-layout-item" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-btn :disabled=" uploading || !$v.studio.featureList.$anyDirty" class="first-continue-button" type="submit">
                {{$t('uploads.save')}}

              </v-btn>
              <i class="md-helper-text" v-if="!$v.studio.featureList.$anyDirty">
                {{$t('uploads.waiting')}}
              </i>
              <!-- show loading/submitting thing -->
              <div>
                <v-progress-circular indeterminate color="primary" v-if="uploading && !studio.featureList.complete" :size="30" :width="3"></v-progress-circular>
              </div>
            </div>
            <v-radio-group :hint="$t('features.bedTypeHint')" :error-messages="bedTypeError" :label="$t('features.bedType')" v-model="$v.studio.featureList.bedType.$model">
              <v-radio v-for="(bedType, index) in bedTypes" color="primary" :label="bedType.text" :value="bedType.value" :key="index"></v-radio>
            </v-radio-group>
            <v-radio-group :hint="$t('features.sizeHint')" :error-messages="sizeError" :label="$t('features.size')" v-model="$v.studio.featureList.size.$model">
              <v-radio v-for="(size, index) in studioSizes" color="primary" :label="size.text" :value="size.val" :key="index"></v-radio>
            </v-radio-group>

            <Bathroom v-for="(bathroom, index) in $v.studio.featureList.bathrooms.$model" :key="index" :user="user" :bathroom='bathroom' :index='index' :disabledRemove='true' @removeBathroom="removeBathroom($event)" @bathroomUpdated="setBathroom($event, index)" />
            <v-select :items="kitchenOptions" :error-messages="kitchenErrors" v-model="$v.studio.featureList.kitchenType.$model" :label="$t('features.kitchen')"></v-select>
            <v-flex xs12>
              <v-checkbox label="Cleaned by a professional BEFORE the arrival of each tenant" v-model="studio.featureList.cleaned" @change="$v.studio.featureList.furniture.$touch()"></v-checkbox>
            </v-flex>

            <v-layout  wrap>
              <v-flex xs12>

                <span class="v-label">
                  {{$t('features.furniture')}}
                </span>
              </v-flex>
              <v-flex xs4 v-for="(furniture,index) in studioFurnitures" :key="index" :value="index">
                <v-checkbox v-model="studio.featureList.furniture" multiple color="primary" :value="index" @change="$v.studio.featureList.furniture.$touch()">
                  <template slot="label">
                    <span><span :class="furniture.icon"></span> {{$t("features."+index)}}</span>
                  </template>

                </v-checkbox>

              </v-flex>
            </v-layout>
            <v-layout  wrap>
              <v-flex xs12>

                <span class="v-label">
                  {{$t('features.features')}}
                </span>
              </v-flex>
              <v-flex xs4 v-for="(feature,index) in studioFeatures" :key="index" :value="index">
                <v-checkbox v-model="studio.featureList.features" multiple color="primary" :value="index" @change="$v.studio.featureList.features.$touch()">
                  <template slot="label">
                    <span><span :class="feature.icon"></span> {{$t("features."+index)}}</span>
                  </template>

                </v-checkbox>

              </v-flex>
            </v-layout>
            <v-layout  wrap>

              <v-btn color="primary" flat @click="back">
                {{$t('uploads.back')}}
              </v-btn>
              <v-spacer></v-spacer>

              <div class="submitContainer md-size-100 md-layout-item text-xs-right" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
                <v-btn :disabled=" uploading || !$v.studio.featureList.$anyDirty" class="first-continue-button" type="submit">
                  {{$t('uploads.save')}}
                </v-btn>
                <i class="md-helper-text" v-if="!$v.studio.featureList.$anyDirty">
                  {{$t('uploads.waiting')}}
                </i>
                <!-- show loading/submitting thing -->
                <div>
                  <v-progress-circular indeterminate color="primary" v-if="uploading && !studio.featureList.complete" :size="30" :width="3"></v-progress-circular>
                </div>
              </div>
            </v-layout>
          </form>

        </v-tab-item>
        <v-tab-item id="tab-three">
          <form novalidate @submit.prevent="submitApt('contractDetails')">
            <div class="submitContainer md-size-100 md-layout-item" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-btn :disabled=" uploading || !$v.studio.contractDetails.$anyDirty" class="first-continue-button" type="submit">
                {{$t('uploads.save')}}
              </v-btn>
              <i class="" v-if="!$v.studio.contractDetails.$anyDirty">
                {{$t('uploads.waiting')}}
              </i>
              <!-- show loading/submitting thing -->
              <div>
                <v-progress-circular indeterminate color="primary" v-if="uploading && !studio.contractDetails.complete" :size="30" :width="3"></v-progress-circular>
              </div>
            </div>
            <v-select :items="contractLengthOptions" :error-messages="cLengthError" v-model="$v.studio.contractDetails.contractLength.$model" :label="$t('contractDetails.minLength')" @change="setContractLengthVal" :hint="$t('contractDetails.minLengthHint')">
            </v-select>
            <v-select :items="contractFlexibilityOptions" :error-messages="cFlexError" v-model="$v.studio.contractDetails.contractFlexibility.$model" :label="$t('contractDetails.contractFlexibility')" :hint="$t('contractDetails.contractFlexibilityHint')">
            </v-select>
            <v-select :items="contractLanguageOptions" :error-messages="clangError" v-model="$v.studio.contractDetails.contractLanguage.$model" :label="$t('contractDetails.contractLanguage')" :hint="$t('contractDetails.contractLanguageHint')"></v-select>
            <v-layout  wrap>
              <v-flex xs12>
                <div class="title">
                  {{$t('contractDetails.rules')}}
                </div>
              </v-flex>
              <v-flex xs4 v-for="(rule,index) in studio.contractDetails.rules" :key="'rule_'+index">
                <v-switch label="rule.name" color="primary" v-model="rule.value" @change="$v.studio.contractDetails.rules.$touch()">
                  <template slot="label">
                    <span><span :class="'MRAIcon_'+index"></span> {{$t("contractDetails.rulesTexts."+index)}}</span>
                  </template>
                </v-switch>

              </v-flex>
            </v-layout>
            <v-textarea :label="$t('contractDetails.otherRules')" v-model="studio.contractDetails.otherRules" :rows="2" :hint="$t('contractDetails.otherRulesHint')" @input="$v.studio.contractDetails.otherRules.$touch()" @blur="$v.studio.contractDetails.otherRules.$touch()">
            </v-textarea>
            <v-layout  wrap>

              <v-btn color="primary" flat @click="back">{{$t('uploads.back')}}</v-btn>
              <v-spacer></v-spacer>

              <div class="submitContainer md-size-100 text-xs-right md-layout-item" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
                <v-btn :disabled=" uploading || !$v.studio.contractDetails.$anyDirty" class="first-continue-button" type="submit">
                  {{$t('uploads.save')}}
                </v-btn>
                <i class="md-helper-text" v-if="!$v.studio.contractDetails.$anyDirty">
                  {{$t('uploads.waiting')}}
                </i>
                <!-- show loading/submitting thing -->
                <div>
                  <v-progress-circular indeterminate color="primary" v-if="uploading && !studio.contractDetails.complete" :size="30" :width="3"></v-progress-circular>
                </div>
              </div>
            </v-layout>
          </form>
        </v-tab-item>


        <v-tab-item id="tab-four">
          <form novalidate @submit.prevent="submitApt('billing')">


            <div class="submitContainer" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-btn :disabled=" uploading || !$v.studio.billing.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
                {{$t('uploads.save')}}
              </v-btn>
              <i class="md-helper-text" v-if="!$v.studio.billing.$anyDirty">
                {{$t('uploads.waiting')}}
              </i>
              <v-btn  v-if="$route.query.studioId!=undefined" :disabled=" uploading  || !$v.studio.billing.$anyDirty" color="primary" class="mx-4 first-continue-button section-date md-raised md-primary md-layout-item" @click="exit('billing')">
                {{$t('uploads.finish')}}
              </v-btn>

              <!-- show loading/submitting thing -->
              <div class="">
                <v-progress-circular indeterminate color="primary" v-if="uploading && !studio.billing.complete" :size="30" :width="3"></v-progress-circular>
              </div>
            </div>
            <v-layout  wrap>
              <v-flex xs9 class="pr-4">
                <v-text-field v-model="studio.billing.price" :label="$t('billing.rent')" :error-messages="priceErrors" type="number" suffix="NT$" :hint="$t('billing.rentHint')" @input="$v.studio.billing.price.$touch()" @blur="$v.studio.billing.price.$touch()"></v-text-field>
              </v-flex>
              <v-flex xs3 >
                <v-text-field label="You get" :value="youGetVal" type="number" suffix="NT$" disabled ></v-text-field>
              </v-flex>
              <v-flex xs12 v-if="this.user.roleValue>40 || this.user.margin==false">
                <v-text-field v-model="studio.billing.llPrice" label="Price to send to landlord" :error-messages="priceErrors" type="number" suffix="NT$" hint="The price the landlord will actually get - Not margin method" @input="$v.studio.billing.price.$touch()" @blur="$v.studio.billing.price.$touch()"></v-text-field>
              </v-flex>
            </v-layout>
            <v-text-field v-model="studio.billing.deposit" :error-messages="depositErrors" :label="$t('billing.deposit')" type="number" suffix="NT$" :hint="$t('billing.depositHint')" @input="$v.studio.billing.deposit.$touch()" @blur="$v.studio.billing.deposit.$touch()"></v-text-field>
            <v-switch
              v-if="studio.billing.price!=''"
              :label="$t('pricing.flexprice')"
              v-model="$v.studio.billing.flexPrice.$model"
              @change="switchFlexPrice"
            ></v-switch>
            <div class="ml-5" v-if="studio.billing.flexPrice==true">

              <v-layout wrap>
                <v-flex xs12>
                  <div class="md-helper-text" v-if="!$v.studio.billing.$anyDirty">
                    {{$t('pricing.help')}}
                  </div>
                  <v-layout  wrap>
                    <v-flex xs5 class="title pr-4">
                      {{$t('pricing.rent')}}
                    </v-flex>
                    <v-flex xs2 class="title pr-4">
                      You Get
                    </v-flex>
                    <v-flex xs5 class="title pl-4">
                      {{$t('pricing.deposit')}}
                    </v-flex>

                  </v-layout>
                </v-flex>
                </v-layout>
                <v-layout v-if="cLengthVal<3"  wrap>
                  <v-flex xs5 class="pr-4">
                    <v-text-field  v-model="studio.billing.flexPricing.month" :label="$t('pricing.monthly')" type="number" suffix="NT$" :hint="$t('pricing.monthlyHelp')" @input="$v.studio.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-4">
                    <v-text-field  :value="Math.floor(studio.billing.flexPricing.month*.97)" type="number" suffix="NT$" disabled ></v-text-field>
                  </v-flex>
                  <v-flex xs5 class="pl-4">
                    <v-text-field  v-model="studio.billing.flexPricing.monthD" :label="$t('pricing.monthly')" type="number" suffix="NT$" :hint="$t('pricing.monthlyHelpD')" @input="$v.studio.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>

                </v-layout>

                <v-layout v-if="cLengthVal<5"  wrap>
                  <v-flex xs5 class="pr-4">
                    <v-text-field  v-model="studio.billing.flexPricing.three" :label="$t('pricing.three')" type="number" suffix="NT$" :hint="$t('pricing.threeHelp')" @input="$v.studio.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-4">
                    <v-text-field  :value="Math.floor(studio.billing.flexPricing.three*.97)" type="number" suffix="NT$" disabled ></v-text-field>
                  </v-flex>
                  <v-flex xs5 class="pl-4">
                    <v-text-field  v-model="studio.billing.flexPricing.threeD" :label="$t('pricing.three')" type="number" suffix="NT$" :hint="$t('pricing.threeHelpD')" @input="$v.studio.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>

                </v-layout>

                <v-layout v-if="cLengthVal<12"  wrap>
                  <v-flex xs5 class="pr-4">
                    <v-text-field  v-model="studio.billing.flexPricing.five" :label="$t('pricing.five')" type="number" suffix="NT$" :hint="$t('pricing.fiveHelp')" @input="$v.studio.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-4">
                    <v-text-field  :value="Math.floor(studio.billing.flexPricing.five*.97)" type="number" suffix="NT$" disabled ></v-text-field>
                  </v-flex>
                  <v-flex xs5 class="pl-4">
                    <v-text-field  v-model="studio.billing.flexPricing.fiveD" :label="$t('pricing.five')" type="number" suffix="NT$" :hint="$t('pricing.fiveHelpD')" @input="$v.studio.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>

                </v-layout>

                <v-layout  wrap>
                  <v-flex xs5 class="pr-4">
                    <v-text-field v-model="studio.billing.flexPricing.year" :label="$t('pricing.year')" type="number" suffix="NT$" :hint="$t('pricing.yearHelp')" @input="$v.studio.billing.flexPrice.$touch()"></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-4">
                    <v-text-field  :value="Math.floor(studio.billing.flexPricing.year*.97)" type="number" suffix="NT$" disabled ></v-text-field>
                  </v-flex>
                  <v-flex xs5 class="pl-4">
                    <v-text-field v-model="studio.billing.flexPricing.yearD" :label="$t('pricing.year')" type="number" suffix="NT$" @input="$v.studio.billing.flexPrice.$touch()" :hint="$t('pricing.yearHelpD')" ></v-text-field>
                  </v-flex>

                </v-layout>
            </div>

            <v-select :items="utilitiesOptions" :error-messages="utilitiesError" v-model="studio.billing.utilities" :label="$t('billing.utilities')" @change="setUtilitiesOptions" :hint="$t('billing.utilitiesHint')"></v-select>
            <v-text-field type="number"  v-model="studio.billing.utilitiesAmount" suffix="NT$" :label="$t('billing.utilitiesAmount')" v-if="showUtilAmount" @input="$v.studio.billing.utilitiesAmount.$touch()" @blur="$v.studio.billing.utilitiesAmount.$touch()"></v-text-field>
            <v-textarea v-model="studio.billing.utilitiesComment" :label="$t('billing.utilitiesComment')" rows="2" v-if="showUtilComment" :hint="$t('billing.utilitiesCommentHint')" @input="$v.studio.billing.utilitiesComment.$touch()"
            @blur="$v.studio.billing.utilitiesComment.$touch()"></v-textarea>
            <v-switch color="primary" :label="$t('billing.couplesAllowed')" :error-messages="couplesAllowedError" v-model="$v.studio.billing.couplesAllowed.$model"></v-switch>
            <v-text-field v-if="studio.billing.couplesAllowed" :error-messages="couplesExtraUtilitiesError" v-model="studio.billing.couplesExtraUtilities" :label="$t('billing.couplesExtraUtilities')" type="number" suffix="NT$" :hint="$t('billing.couplesExtraUtilitiesHint')" @input="$v.studio.billing.couplesExtraUtilities.$touch()"
            @blur="$v.studio.billing.couplesExtraUtilities.$touch()"></v-text-field>

            <v-layout  wrap>

              <v-btn color="primary" flat @click="back">
                {{$t('uploads.back')}}
              </v-btn>
              <v-spacer></v-spacer>

              <div class="submitContainer text-xs-right" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
                <v-btn  v-if="$route.query.studioId!=undefined" :disabled=" uploading  || !$v.studio.billing.$anyDirty" color="primary" class="mx-4 first-continue-button section-date md-raised md-primary md-layout-item" @click="exit('billing')">
                  {{$t('uploads.finish')}}
                </v-btn>
                <v-btn :disabled=" uploading || !$v.studio.billing.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
                  {{$t('uploads.save')}}
                </v-btn>
                <i class="md-helper-text" v-if="!$v.studio.billing.$anyDirty">
                  {{$t('uploads.waiting')}}
                </i>
                <!-- show loading/submitting thing -->
                <div class="">
                  <v-progress-circular indeterminate color="primary" v-if="uploading && !studio.billing.complete" :size="30" :width="3"></v-progress-circular>
                </div>
              </div>
            </v-layout>
          </form>
        </v-tab-item>
        <v-tab-item id="tab-five">

          <div class="display-1 text-xs-center justify-center pa-2">{{$t('generalInfo.tenantsText')}}</div>
          <AddTenant @addNewTenant="addNewTenant"></AddTenant>
          <v-layout  justify-center wrap>
            <v-btn color="primary" flat class="" @click="finish">{{$t('uploads.save')}}</v-btn>
          </v-layout>
          <br>
          <hr>
          <div class="title pa-3">
            <strong>{{$t('generalInfo.currentTenants')}}</strong>
          </div>

          <showTenant v-for="(tenant,index) in studio.tenants.tenants" :key="index" :tenant="tenant" :index="index" @removeTenant="removeTenant"></showTenant>
          <v-layout  wrap>

            <v-btn color="primary" flat @click="back">{{$t('uploads.back')}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="tabs='tab-six'">{{$t('uploads.save')}}</v-btn>

          </v-layout>

        </v-tab-item>
        <v-tab-item id="tab-six">
          <section>
            <div class="title">{{$t("visual.showTenants")}}</div>
            <div class="subheading">{{$t('visual.addVideo')}}</div>
            <v-text-field v-model="studio.visual.video" dont-fill-mask-blanks browser-autocomplete="disable" :label="$t('visual.video')" :hint="$t('visual.videoHint')" @change="addYoutubeLink"></v-text-field>
            <Dropzone id="photos" @onUpload="uploadPhotos" @onSorted="sortedPhotos" :photos="studio.visual.photos" :apartment="true" :tag="tagOptions" :landlordId="user.id" @addPhoto='addPhoto($event,"studio")'>
            </Dropzone>
          </section>
          <v-layout  wrap>
            <v-btn color="primary" flat @click="back">{{$t('uploads.back')}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="finish">{{$t('uploads.finish')}}</v-btn>

          </v-layout>
        </v-tab-item>
      </v-tabs-items>

    </v-flex>

  </v-layout>
  <v-snackbar v-model="showSnackbar" color="success" :timeout="6000">
    {{snackbarMessage}}
    <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
  </v-snackbar>
</div>
</template>
<script>
import axios from 'axios'
import Loading from '../shared/Loading.vue'
import Dropzone from '../Dropzone.vue'
import UploadOSM from './UploadOSM.vue'
import moment from 'moment'
import Bathroom from './Bathroom.vue'
import {
  mapGetters
} from 'vuex'
import ShowTenant from './ShowTenant.vue'
import AddTenant from './AddTenant.vue'
import * as types from '../../store/types'
import draggable from 'vuedraggable'
import AWSSES from '../../aws-ses'

import OpenMapsListings from '../roomDetails/OpenMapsListings.vue'

import {
  minValue,
  required,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
// AWSSES.emailing(userData, 'accountDeleted')


export default {

  data: () => ({
    tabs: "tab-one",
    disabledRelist:false,
    showSnackbar: false,
    snackbarMessage: "",
    initialLoad: true,
    showUtilComment:true,
    menu2: false,
    studioDraft: true,
    cLengthVal:12,

    deleteStudioDialog: false,
    studioFurnitures: {
      ac: {
        value: true,
        icon: 'MRAIcon_air_conditioning',
      },
      fan: {
        value: false,
        icon: 'MRAIcon_fan',
      },
      curtains: {
        value: false,
        icon: 'MRAIcon_curtains',
      },
      mattress: {
        value: true,
        icon: 'MRAIcon_mattress',
      },
      pillow: {
        value: true,
        icon: 'MRAIcon_pillow',
      },
      sheets: {
        value: true,
        icon: 'MRAIcon_sheets',
      },
      desk: {
        value: true,
        icon: 'MRAIcon_desk',
      },
      chair: {
        value: true,
        icon: 'MRAIcon_chair',
      },
      wardrobe: {
        value: true,
        icon: 'MRAIcon_wardrobe',
      },
      openHanger: {
        value: false,
        icon: 'MRAIcon_open_hanger',
      },
      shelve: {
        value: false,
        icon: 'MRAIcon_shelves',
      },
      washingMachine: {
        value: false,
        icon: 'MRAIcon_laundry',
      },
      tv: {
        value: false,
        icon: 'MRAIcon_tv',
      },
      couch: {
        value: false,
        icon: 'MRAIcon_couch',
      },
      coffeeTable: {
        value: false,
        icon: 'MRAIcon_coffee_table',
      },
    },
    studioFeatures: {
      privateBathroom: {
        value: false,
        icon: 'MRAIcon_private_bathroom',
      },
      outsideWindow: {
        value: true,
        icon: 'MRAIcon_outside_window',
      },

      innerWindow: {
        value: false,
        icon: 'MRAIcon_inner_window',
      },
      wifi: {
        value: false,
        icon: 'MRAIcon_wifi',
      },
      balcony: {
        value: false,
        icon: 'MRAIcon_balcony',
      },
      rooftop: {
        value: false,
        icon: 'MRAIcon_rooftop',
      },
      elevator: {
        value: false,
        icon: 'MRAIcon_elevator',
      },
      security: {
        value: false,
        icon: 'MRAIcon_security',
      },
      cleaningService: {
        value: false,
        icon: 'MRAIcon_cleaning',
      },
      trashService: {
        value: false,
        icon: 'MRAIcon_trash_service',
      }
    },

    studio: {
      verifiedApartment: false,
      lastModified: new Date(),
      general: {
        landlordId: '',
        description: "",
        name: "",
        title: "",
        location: {
          lat: 0,
          lng: 0,
        },
        dateAdded: new Date(),
        complete: false,
      },
      featureList: {
        complete: false,
        bathrooms: [{
          type: 'Wet Bathroom'
        }],
        size: "",
        cleaned:false,
        bedType: "",
        kitchenType: "None",
        features: ['wifi', 'privateBathroom', 'outsideWindow'],
        furniture: ['ac', 'mattress', 'chair', 'desk', 'pillow', 'washingMachine'],
      },
      billing: {
        complete: false,
        deposit: "",
        price: "",
        couplesAllowed: false,
        couplesExtraUtilities: 0,
        flexPrice:false,
        llPrice:"",
      },
      contractDetails: {
        complete: false,
        otherRules: "",
        rules: {
          noSmoking: {
            value: true
          },
          noPets: {
            value: true,
          },
          girlsOnly: {
            value: false
          },
          noParties: {
            value: false
          },
          noGuests: {
            value: false,
          },
          extensiveIntro: {
            value: false,
          },
          noSublease: {
            value: false,
          }
        },
        contractLanguage: 'English',
        contractLength: '1 month',
        contractFlexibility: '',
        utilities: 'Included in rent',
        utilitiesAmount: 0,
        utilitiesComment: '',

      },
      tenants: {
        complete: false,
        tentants: []
      },
      visual: {
        photos: [],
        video: "",
      }
    },
    editing: false,
    landlords: [],
    uploading: false,
    errorMessage: "",
    studioId: "",
    loading: false,
    admin: {
      email: 'hello@myroomabroad.com',
      name: 'Admin',
    },

  }),

  validations: {
    studio: {
      general: {
        title: {
          required,
          minLength: minLength(4)
        },
        description: {
          required,
          maxlength: maxLength(500),
        },
        nextAvailability: {
          required
        },
        successfulBookings: {},
        name: {
          required,
          nonChinese(name){
            return(/^[\x20-\x7E]*$/.test(name))
          }

        },
        landlordId: {
          required
        },
        floor: {
          required
        },
        location: {
          lat:{
            required,
            minValue: minValue(1)
          },
          lng:{
            required,
            minValue: minValue(1)
          },
        },
      },
      featureList: {
        bathrooms: {
          required,
          minLength: minLength(1)
        },
        kitchenType: {
          required
        },
        bedType: {
          required
        },
        size: {
          required
        },
        features: {},
        furniture: {

        }
      },
      billing: {
        deposit: {
          required
        },
        price: {
          required
        },
        couplesAllowed: {
          required
        },
        couplesExtraUtilities: {
          required
        },
        utilities: {
          required
        },
        flexPrice:{
        },

        utilitiesAmount: {

        },
        utilitiesComment: {

        },

      },

      contractDetails: {
        contractLength: {
          required
        },
        contractFlexibility: {
          required
        },
        contractLanguage: {
          required
        },
        rules: {},
        otherRules: {},

      },
    }
  },
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 150,
        behavior: "smooth"
      })
    },
    checkllPrice(){
      if (this.studio.billing.llPrice!="" && this.studio.billing.llPrice!=undefined){
        if (parseInt(this.studio.billing.llPrice)>parseInt(this.studio.billing.price)){
          if (this.user.roleValue>50){
            alert("Don't forget to decrease the Price Sent to landlord!")
          } else {
            let admin={
              name:"Admin",
              email:"hello@myroomabroad.com"
            }
            let info={
              url:'https://myroomabroad.com'+this.$route.fullPath,
              room:this.studio.general.name,
            }
            AWSSES.emailingInfo(admin, info, 'ALLPriceTooHigh')
            console.log('sendEmail');
          }
        }
      }
    },
    relist(){
      this.disabledRelist=true
      axios.patch('studios/'+this.studioId+'.json/?auth='+this.$store.state.auth.idToken,{draft:false})
      .then(()=>{
        if (this.studio.general.landlordId!=this.user.id){
          this.$router.push({
            name:'adminLandlordDashboard',
            params:{
              id:this.landlordId
            }
          })
        } else {
          this.$router.push({
            name: 'LandlordDashboard'
          })
        }
      })
      .catch(err=>{
        console.log('error: ',err);
        this.disabledRelist=false
      })

    },

    setLocation(payload){
      this.studio.general.location={}
      console.log('the studio location exists:', this.studio.general.location.lat, this.studio.general.location.lng)
      console.log('setLocation', payload.lat, payload.lng);
      this.studio.general.location.lat= payload.lat
      this.studio.general.location.lng= payload.lng
      this.$v.studio.general.location.lat.$touch()
      this.$v.studio.general.location.lng.$touch()
      console.log('setLocation', this.studio.general.location.lat, this.studio.general.location.lng);
      console.log('the $v', this.$v.studio.general);
    },
    setContractLengthVal(){
      switch (this.studio.contractDetails.contractLength) {
        case '1 month':
          this.cLengthVal=1
          break;
        case '3 months':
          this.cLengthVal=3
          break;
        case '5 months':
          this.cLengthVal=5
          break;
        case '12 months':
          this.cLengthVal=12
          break;
        default:
          this.cLengthVal=12
      }
    },
    switchFlexPrice(){
      this.setContractLengthVal()
      this.studio.billing.flexPricing={}
      if (this.studio.billing.flexPrice==true){
        this.studio.billing.flexPricing.month=Math.floor(1.3*this.studio.billing.price)
        this.studio.billing.flexPricing.three=Math.floor(1.2*this.studio.billing.price)
        this.studio.billing.flexPricing.five= Math.floor(1.1*this.studio.billing.price)
        this.studio.billing.flexPricing.year=this.studio.billing.price
        this.studio.billing.flexPricing.monthD=5000
        this.studio.billing.flexPricing.threeD=this.studio.billing.flexPricing.three
        this.studio.billing.flexPricing.fiveD= this.studio.billing.flexPricing.five*2
        this.studio.billing.flexPricing.yearD=this.studio.billing.flexPricing.year*2
      }
    },
    setUtilitiesOptions(payload){
      this.$v.studio.billing.utilities.$touch()
      console.log(payload)
      switch (payload) {
        case 'Included in rent':
          this.studio.billing.utilitiesAmount=0
          // this.studio.billing.utilitiesComment=""
          break;
        case 'Fixed monthly amount':
          break;
        case 'Split by tenants':
          this.studio.billing.utilitiesAmount=0
          break;
        case 'Other':
          this.studio.billing.utilitiesAmount=0
          break;

        default:

      }
    },

    /*****
    Visual stuffs
    *******/
    uploadPhotos(x) {
      // console.log('photos uploaded -->', x);
      if (!this.studio.visual.hasOwnProperty('photos')) {
        this.studio.visual.photos = []
      }
      this.studio.visual.photos.push(x);
      this.updateApt('visual', this.studio.visual);
    },
    sortedPhotos(x) {
      // console.log('photos uploaded -->', x);
      this.studio.visual['photos'] = x;
      this.updateApt('visual', this.studio.visual);
    },
    addYoutubeLink() {
      if (this.studio.visual.video.includes('youtube')) {
        // console.log('upload');
        this.updateApt('visual', this.studio.visual);
      } else {
        alert('No "youtube" in link, please insert the valid url')
      }
    },

    back() {
      switch (this.tabs) {
        case 'tab-six':
          this.tabs = 'tab-five'
          this.scrollTo()
          break
        case 'tab-five':
          this.tabs = 'tab-four'
          this.scrollTo()
          break
        case 'tab-four':
          this.tabs = 'tab-three'
          this.scrollTo()
          break;
        case 'tab-three':
          this.tabs = "tab-two"
          this.scrollTo()
          break
        case 'tab-two':
          this.tabs = "tab-one"
          this.scrollTo()
          break
      }
    },

    /*****
    Bathroom stuffs
    *******/
    deleteStudio() {
      axios.patch('/studios/' + this.studioId + '.json?auth=' + this.$store.state.auth.idToken, {
          draft: true
        })
        .then(() => {
          this.$router.push({
            name: 'LandlordDashboard'
          })
        })
        .catch(err=>{
          console.log('error: ',err);
        })
    },
    addBathroom() {
      // console.log('add a bathroom')
      // console.log(this.$v.studio.$model);
      if (!this.studio['featureList'].hasOwnProperty('bathrooms')) {
        // console.log('there si no bathroom prop');
        this.$v.studio.featureList.$model = {
          'bathrooms': []
        };
      }

      this.$v.studio['featureList']['bathrooms'].$model.push({
        type: 'Wet Bathroom',
      });
      this.$v.studio['featureList']['bathrooms'].$touch();
    },
    removeBathroom(e) {
      // console.log('remove ' + e)
      this.$v.studio['featureList']['bathrooms'].$model.splice(e, 1);
      this.$v.studio['featureList']['bathrooms'].$touch();
    },
    setBathroom(e, i) {
      // console.log('bathroom', e, i);
      // console.log(typeof this.$v.studio.featureList.bathrooms.$model);
      // this.studio.featureList.bathrooms[i] = e;
      if (!this.$v.studio.featureList.bathrooms.$model == "object") {
        // if(typeof this.$v.studio.featureList.hasOwnProperty('bathrooms')){
        this.$v.studio.featureList.bathrooms.$model = [e]
      } else {
        this.$v.studio.featureList.bathrooms.$model[i] = e;
      }
      this.$v.studio['featureList']['bathrooms'].$touch();
    },





    /*****
    Validation stuffs
    *******/

    touchField(section) {
      // console.log('touch field...', section, field);
      this.$v.studio[section].$touch();
    },

    //setting the class on the field to show errors
    getValidationClass(section, fieldName) {
      // console.log('get validation class');
      // const vsection = this.$v.studio[section]
      const field = this.$v.studio[section][fieldName]
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    //validating the section, if valid then submit to server
    validateSection(section) {
      // console.log('validate section');
      this.$v.studio[section].$touch()
      if (!this.$v.studio[section].$invalid && !this.$v.studio[section].$anyError) {
        return true;
      } else {
        return false;
      }
    },

    /**************
      Tenants Section
    **************/

    addNewTenant(payload) {
      // console.log('payload', payload);
      if (this.studio.tenants['tenants'] === undefined) {
        this.studio.tenants['tenants'] = [];
      }
      this.studio.tenants['tenants'].push(payload.tenant)
      this.studio.tenants['tenants']['complete'] = true;


      this.updateApt('tenants', this.studio.tenants);

    },
    removeTenant(index) {
      this.studio.tenants.tenants.splice(index, 1);
      this.updateApt('tenants', this.studio.tenants.tentants);


    },
    noPreviousTenants() {
      this.updateApt('tenants', {
        'unLeased': true
      });
    },





    /**************
      Submit Section
    **************/
    checkOtherForms() {
      if (this.studio.general.complete && this.studio.featureList.complete && this.studio.billing.complete && this.studio.visual.complete && this.studio.contractDetails.complete) {
        if (this.studio.visual.photos&& this.studio.visual.photos.length>0){
          this.studioDraft=false
        }
      } else {
        this.studioDraft = true
      }
    },
    submitApt(section) {
      this.validateSection(section) ? this.updateApt(section, this.studio[section]) : null;
    },
    updateApt(formName, formData) {
      let vThis = this;
      let object = {}
      formData['complete'] = true;
      // console.log("formData:", formData)
      object[formName] = formData;
      this.checkOtherForms()
      if (formName=="billing"){
        this.checkllPrice()
      }
      if (this.studioDraft ==false) {
        object['draft'] = false
        // console.log('draft is false');
      } else{
        object['draft'] = true
        // console.log('draft is true');
      }
      console.log('set trust');
      if (this.studio.general.landlordId==this.user.id){
        console.log('its the landlord', this.user);
        object['trustVal']=this.user.trustVal
      } else {

        console.log('its not the landlord so find it', this.studio.general.landlordId, this.landlords);
        let vm=this
        let ll = this.landlords.find(function(el) {
          return el.id==vm.studio.general.landlordId;
        });
        console.log('the landlord:', ll);
        object['trustVal']=ll.trustVal
      }

      if (formName == "general") {
        // console.log('fulllRoomNumber');

        object.general['fullRoomNumber'] = vThis.studio.general.name
      }
      object['lastModified'] = new Date()
      // TODO is this necessary?
      this.studio.lastModified = new Date()
      this.uploading = true
      this.draft = false
      axios.patch('/studios/' + this.studioId + '.json' + '?auth=' + this.$store.state.auth.idToken, object)
        .then(() => {
          // console.log('patch of studio success')
          if (vThis.originalLandlord != this.studio.general.landlordId) {
            axios.get('/users/' + vThis.originalLandlord + '/studios.json')
              .then(res => {
                // console.log('RES:');
                // console.log(res)
                for (var studio in res.data) {
                  if (res.data.hasOwnProperty(studio)) {
                    if (res.data[studio].studioId === vThis.studioId) {
                      // console.log('you found it')
                      // console.log(studio)
                      axios.delete('/users/' + vThis.originalLandlord + '/studios/' + studio + '.json' + '?auth=' + vThis.$store.state.auth.idToken)
                        .then(() => {
                          // console.log('Delete successful')
                        })
                        .catch(err=>{
                          console.log('error: ',err);
                        })
                    axios.post('/users/' + vThis.studio.general.landlordId + '/studios.json' + '?auth=' + vThis.$store.state.auth.idToken, {
                          studioId: vThis.studioId

                        })
                        .then(() => {
                          // console.log('added successfully')
                        })
                        .catch(err=>{
                          console.log('error: ',err);
                        })
                    }
                  }
                }
              })
              .catch(err => {
                // console.log('error:')
                console.log(err);
              })
          }
        })
        .catch(err=>{
          console.log('error: ',err);
        })
        .finally(() => {
          vThis.uploading = false
          vThis.draft = false
          vThis.cleanUpPosting()
          vThis.nextTab()

        })
    },

    cleanUpPosting() {
      // console.log('snackba should be there')
      this.snackbarMessage = this.$t('uploads.snackbarMessage')
      this.showSnackbar = true;
      this.uploading = false;
      this.complete = true;
      //closes the snackbar
      setTimeout(function() {
        this.complete = false;
      }, 4000);
      this.$v.$reset();
    },
    nextTab() {

      switch (this.tabs) {
        case 'tab-one':
          this.tabs = 'tab-two'
          this.scrollTo()
          break;
        case 'tab-two':
          this.tabs = "tab-three"
          this.scrollTo()
          break
        case 'tab-three':
          this.tabs = "tab-four"
          this.scrollTo()
          break
        case 'tab-four':
          this.tabs = "tab-five"
          this.scrollTo()
          break
        default:
          break
      }

    },
    exit(item){
      this.submitApt(item)
      if (this.studio.general.landlordId!=this.user.id){
        this.$router.push({
          name:'adminLandlordDashboard',
          params:{
            id:this.studio.general.landlordId
          }
        })
      } else {
        this.$router.push({
          name: 'LandlordDashboard'
        })
      }
    },
    finish() {
      if (this.tabs == 'tab-five') {
        // console.log("i'm here!!!!")
        // console.log(this.tabs)
        this.tabs = 'tab-six'
      } else {
        // console.log('finish')
        if (this.studio.general.landlordId!=this.user.id){
          this.$router.push({
            name:'adminLandlordDashboard',
            params:{
              id:this.studio.general.landlordId
            }
          })
        } else {
          this.$router.push({
            name: 'LandlordDashboard'
          })
        }
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    let vThis = this
    if (this.draft == true) {
      axios.get('/users/' + vThis.originalLandlord + '/studios.json')
        .then(res => {
          // console.log('RES:');
          // console.log(res)
          for (var studio in res.data) {
            if (res.data.hasOwnProperty(studio)) {
              if (res.data[studio].studioId === vThis.studioId) {
                // console.log('you found it')
                // console.log(studio)
                axios.delete('/users/' + vThis.originalLandlord + '/studios/' + studio + '.json' + '?auth=' + vThis.$store.state.auth.idToken)
                  .then(() => {
                    // console.log('Delete successful')
                  })
                  .catch(err=>{
                    console.log('error: ',err);
                  })
                axios.delete('/studios/' + vThis.studioId + '.json' + '?auth=' + vThis.$store.state.auth.idToken)
                  .then(() => {
                    // console.log('studio Deleted');
                  })
                  .catch(err=>{
                    console.log('error: ',err);
                  })
              }
            }
          }
        })
        .catch(err => {
          console.log('error:')
          console.log(err);
        })
        .finally(() => {
          next()
        })
    } else {
      next()
    }
  },

  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    youGetVal(){
      if (this.studio.billing!=undefined && this.studio.billing.llPrice!="" &&this.studio.billing.llPrice!=undefined){
        return this.studio.billing.llPrice
      } else {
        return Math.floor(parseInt(this.studio.billing.price)*.97)
      }
    },
    showUtilAmount(){

      if (this.studio!=undefined && this.studio.billing!=undefined && this.studio.billing.utilities=="Fixed monthly amount"){
        return true
      } else {
        return false
      }
    },
    unlisted:function(){
      let finished= false
      if (this.studio.general.complete && this.studio.featureList.complete && this.studio.billing.complete && this.studio.visual.complete && this.studio.contractDetails.complete) {
        if (this.studio.visual.photos&& this.studio.visual.photos.length>0){
          finished=true
        }
      }
      if (finished && this.studioDraft){
        return true
      } else {
        return false
      }

    },

    studioSizes(){
      let t=[{
          text: '<20 m² (< 7 '+this.$t('uploads.ping')+')',
          val: 'small',
        },
        {
          text: '20-40 m² (7-13 '+this.$t('uploads.ping')+')',
          val: 'average'
        },
        {
          text: '>40 m² (> 13 '+this.$t('uploads.ping')+')',
          val: 'large'
        }

      ]
      return t
    },
    kitchenOptions:function(){
      let t=[
        'None',
        'Basic cooking facilities',
        'Fully equiped kitchen'
      ]
      if (this!=undefined){
        t=[{
            value: 'None',
            text: this.$t('features.kitchenOptions.none'),
          },
          {
            value: 'Basic cooking facilities',
            text: this.$t('features.kitchenOptions.basic'),
          },
          {
            value: 'Fully equiped kitchen',
            text: this.$t('features.kitchenOptions.full'),
          }]
      }
      return t
    },
    contractLengthOptions: function() {
      let t = ['1 month',
        '3 months',
        '5 months',
        '12 months',
        'Open contract'
      ]
      if (this != undefined) {
        t = [{
            value: '1 month',
            text: this.$t('contractDetails.minLengthOptions.one'),
          },
          {
            value: '3 months',
            text: this.$t('contractDetails.minLengthOptions.three'),
          },
          {
            value: '5 months',
            text: this.$t('contractDetails.minLengthOptions.five'),
          },
          {
            value: '12 months',
            text: this.$t('contractDetails.minLengthOptions.twelve'),
          },
          {
            value: 'Open contract',
            text: this.$t('contractDetails.minLengthOptions.open'),
          }
        ]
      }
      return t
    },

    contractFlexibilityOptions: function() {
      let t = [
        'Starts and ends any day',
        'Starts any day for 1 month',
        'Starts first or 15th',
        'Starts always on the first',
      ]
      if (this != undefined) {
        t = [{
            value: 'Starts and ends any day',
            text: this.$t('contractDetails.contractFlexibilityOptions.any'),
          },
          {
            value: 'Starts any day for 1 month',
            text: this.$t('contractDetails.contractFlexibilityOptions.oneMonth'),
          },
          {
            value: 'Starts first or 15th',
            text: this.$t('contractDetails.contractFlexibilityOptions.firstOr'),
          },
          {
            value: 'Starts always on the first',
            text: this.$t('contractDetails.contractFlexibilityOptions.first'),
          }
        ]
      }
      return t

    },

    contractLanguageOptions: function() {
      let t = [
        'English',
        'English & Chinese',
        'Chinese Only',
      ]
      if (this != undefined) {
        t = [{
            value: "English",
            text: this.$t('contractDetails.contractLanguageOptions.english'),
          },
          {
            value: "English & Chinese",
            text: this.$t('contractDetails.contractLanguageOptions.both'),
          },
          {
            value: "Chinese Only",
            text: this.$t('contractDetails.contractLanguageOptions.chinese'),
          }
        ]
      }
      return t

    },

    utilitiesOptions: function() {
      let t = [
        'Included in rent',
        'Fixed monthly amount',
        'Split by tenants',
        'Other'
      ]
      if (this != undefined) {
        t = [{
            value: "Included in rent",
            text: this.$t('billing.utilitiesOptions.included'),
          },
          {
            value: "Fixed monthly amount",
            text: this.$t('billing.utilitiesOptions.fixed'),
          },
          {
            value: "Split by tenants",
            text: this.$t('billing.utilitiesOptions.split'),
          },
          {
            value: "Other",
            text: this.$t('billing.utilitiesOptions.other'),
          }
        ]
      }
      return t
    },
    bedTypes: function() {
      let t = ['None', 'Single', 'Double']

      if (this != undefined) {
        t = [{
            value: 'None',
            text: this.$t('features.bedTypeOptionNone')
          },
          {
            value: 'Single',
            text: this.$t('features.bedTypeOptionSingle')
          },
          {
            value: 'Double',
            text: this.$t('features.bedTypeOptionDouble')
          }
        ]
      }
      return t
    },


    tagOptions: function() {
      let a = ['Common Areas']
      if (this.studio.featureList.kitchenType != 'None') {
        a.push('Kitchen')
      }
      for (var i = 0; i < this.studio.featureList.bathrooms.length; i++) {

        let item = "Bathroom " + (i + 1) + " (" + this.studio.featureList.bathrooms[i].type.replace(' Bathroom', '') + ")"
        a.push(item)
      }
      return a
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    nameErrors() {
      const errors = []
      if (!this.$v.studio.general.name.$dirty) return errors
      !this.$v.studio.general.name.required && errors.push(this.$t('generalInfo.nameErrReq'))
      !this.$v.studio.general.name.nonChinese && errors.push(this.$t('auth.userNameRegexErr'))
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.studio.general.description.$dirty) return errors
      !this.$v.studio.general.description.maxlength && errors.push(this.$t('generalInfo.descriptionErrMax'))
      !this.$v.studio.general.description.required && errors.push(this.$t('generalInfo.descriptionErrReq'))
      return errors
    },
    landlordErrors() {
      const errors = []
      if (!this.$v.studio.general.landlordId.$dirty) return errors
      !this.$v.studio.general.landlordId.required && errors.push('Landlord is required')
      return errors
    },
    titleErrors() {
      const errors = []
      if (!this.$v.studio.general.title.$dirty) return errors
      !this.$v.studio.general.title.required && errors.push(this.$t('generalInfo.titleErrReq'))
      !this.$v.studio.general.title.minLength && errors.push(this.$t('generalInfo.titleErrMin', {number: this.$v.studio.general.title.$params.minLength.min}))
      return errors
    },
    numberErrors() {
      const errors = []
      if (!this.$v.studio.general.name.$dirty) return errors
      !this.$v.studio.general.name.required && errors.push(this.$t('generalInfo.numberErrReq'))
      return errors
    },
    dateErrors() {
      const errors = []
      if (!this.$v.studio.general.nextAvailability.$dirty) return errors
      !this.$v.studio.general.nextAvailability.required && errors.push(this.$t('generalInfo.dateErrReq'))
      return errors
    },
    floorError(){
      const errors = []
      if (!this.$v.studio.general.floor.$dirty) return errors
      !this.$v.studio.general.floor.required && errors.push(this.$t('generalInfo.floorHint'))
      return errors
    },
    locationErrors(){
      const errors = []
      if (!this.$v.studio.general.location.$dirty){
        return errors
      } else if (this.$v.studio.general.location.$anyError || this.$v.studio.general.location.$invalid){
        errors.push(this.$t('uploads.select'))
      }
      return errors
    },
    priceErrors() {
      const errors = []
      if (!this.$v.studio.billing.price.$dirty) return errors
      !this.$v.studio.billing.price.required && errors.push(this.$t('billing.priceErrReq'))
      return errors
    },
    depositErrors() {
      const errors = []
      if (!this.$v.studio.billing.deposit.$dirty) return errors
      !this.$v.studio.billing.deposit.required && errors.push(this.$t('billing.depositErrReq'))
      return errors
    },

    kitchenErrors() {
      const errors = []
      if (!this.$v.studio.featureList.kitchenType.$dirty) return errors
      !this.$v.studio.featureList.kitchenType.required && errors.push('Please fill out the type of kitchen you have.')
      return errors
    },

    cLengthError(){
      const errors = []
      if (!this.$v.studio.contractDetails.contractLength.$dirty) return errors
      !this.$v.studio.contractDetails.contractLength.required && errors.push(this.$t('contractDetails.minLengthHint'))
      return errors
    },
    cFlexError(){
      const errors = []
      if (!this.$v.studio.contractDetails.contractFlexibility.$dirty) return errors
      !this.$v.studio.contractDetails.contractFlexibility.required && errors.push(this.$t('contractDetails.contractFlexibilityHint'))
      return errors
    },
    clangError(){
      const errors = []
      if (!this.$v.studio.contractDetails.contractLanguage.$dirty) return errors
      !this.$v.studio.contractDetails.contractLanguage.required && errors.push(this.$t('contractDetails.contractLanguageHint'))
      return errors

    },

    utilitiesError(){
      const errors = []
      if (!this.$v.studio.billing.utilities.$dirty) return errors
      !this.$v.studio.billing.utilities.required && errors.push(this.$t('contractDetails.utilitiesHint'))
      return errors
    },
    bedTypeError(){
      const errors=[]
      if (!this.$v.studio.featureList.bedType.$dirty) return errors
      !this.$v.studio.featureList.bedType.required && errors.push(this.$t('features.bedTypeHint'))
      return errors
    },
    sizeError(){
      const errors=[]
      if (!this.$v.studio.featureList.size.$dirty) return errors
      !this.$v.studio.featureList.size.required && errors.push(this.$t('features.sizeHint'))
      return errors
    },
    couplesAllowedError(){
      const errors=[]
      if (!this.$v.studio.billing.couplesAllowed.$dirty) return errors
      !this.$v.studio.billing.couplesAllowed.required && errors.push('Please switch click the switch twice. Something weird happened')
      return errors
    },
    couplesExtraUtilitiesError(){
      const errors=[]
      if (!this.$v.studio.billing.couplesExtraUtilities.$dirty) return errors
      !this.$v.studio.billing.couplesExtraUtilities.required && errors.push(this.$t('billing.couplesExtraUtilitiesHint'))
      return errors
    }
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    if (this.$route.query.edit=="billing"){
      this.tabs='tab-four'
    }

    console.log('create the studio element');
    let vThis = this;
    this.studio.general.landlordId = vThis.$store.state.auth.user.id
    this.originalLandlord = this.studio.general.landlordId
    //save new studio as a draft
    // TODO make a pop up for leaving to remove this
    if (vThis.$route.query.studioId == undefined) {
      vThis.initialLoad = false
      this.draft = true
      axios.post('/studios.json' + '?auth=' + vThis.$store.state.auth.idToken, {
          'draft': true,
          'general': {
            landlordId: vThis.$store.state.auth.user.id,
            complete: false,
            dateAdded: new Date(),
          }
        })
        .then((res) => {
          // console.log('Res after studio is created');
          // console.log(res)
          vThis.studioId = res.data.name
          // console.log('studioID:')
          // console.log(vThis.studioId)
          vThis.originalLandlord = vThis.studio.general.landlordId
          axios.post('/users/' + vThis.$store.state.auth.user.id + '/studios.json' + '?auth=' + vThis.$store.state.auth.idToken, {
              'studioId': vThis.studioId
            })
            .then(() => {
              // console.log('studio has been posted to the user');
            })
            .catch(err=>{
              console.log('error: ',err);
            })
        })
        .catch(err=>{
          console.log('error: ',err);
        })
    }



    if (this.$route.query.studioId) {
      console.log('get the old info');
      this.studioId = this.$route.query.studioId
      axios.get('/studios/' + this.studioId + '.json')
        .then(res => {
          // console.log('studio Found')
          // console.log('')
          this.studioDraft=res.data.draft
          if (this.user.id === res.data.general.landlordId || this.user.roleValue > 50) {
            res.data.general ? vThis.studio['general'] = res.data.general : null;
            res.data.featureList ? vThis.studio['featureList'] = res.data.featureList : null;
            res.data.billing ? vThis.studio['billing'] = res.data.billing : null
            res.data.contractDetails ? vThis.studio['contractDetails'] = res.data.contractDetails : null;
            res.data.tenants ? vThis.studio['tenants'] = res.data.tenants : null;
            res.data.visual ? vThis.studio['visual'] = res.data.visual : null;
            vThis.originalLandlord = vThis.studio.general.landlordId
            // console.log(this.studio)
            this.setUtilitiesOptions()
            vThis.initialLoad = false

            if (res.data.billing.flexPrice==true) {
              this.setContractLengthVal()
            }
            if (vThis.studio.general.location==undefined){
              vThis.studio.general.location={
                lat:0,
                lng:0
              }

            }
            if (vThis.studio.general.nextAvailability!=undefined){
              vThis.studio.general.nextAvailability=moment(vThis.studio.general.nextAvailability).format("YYYY-MM-DD")
            }

          } else {
            this.$router.push({
              name: 'homepage'
            })
          }
        })
        .catch(err => {
          // console.log('could not retrieve the studio data')
          console.log(err)
        })
    }
    if (this.$store.state.auth.user.roleValue > 50) {
      console.log(`get the landlords coz ur admin`);
      axios.get('/users.json?orderBy="roleValue"&startAt(20)')
        .then(res => {
          // console.log('Landlords -->', res.data);
          for (var landlord in res.data) {
            if (res.data.hasOwnProperty(landlord)) {
              // console.log(landlord, " had these properties: ",res.data[landlord])
              let ll = res.data[landlord]
              ll.id = landlord
              this.landlords.push(ll)
            }
          }
          // console.log('Our new landlords', this.landlords)
        })
        .catch(err => {
          console.log(err,'couldnt get the landlords')
        })

    }

  },
  components: {
    Dropzone,
    UploadOSM,
    Bathroom,
    draggable,
    AddTenant,
    ShowTenant,
    Loading,

  },

}
</script>
<style lang="scss" scoped>
.studioContainer {
    .v-tabs {
        .v-badge {
            margin-bottom: 0;
            i {
                margin-bottom: 0 !important;
            }
        }
    }
    .v-tabs__items {
        margin-top: 1em;
    }
    .autocomplete-group {
        padding-top: 24px;
    }
    .features {
        text-align: left;
    }
    .md-disabled {
        color: rgba(0,0,0,.38);
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;
        margin-top: 0;
        padding: 0;
        display: block;
        float: right;
        font-size: 20px;
    }

    .icon-delete-btn :hover {
        cursor: pointer;
        background-color: rgba(54, 183, 254, 1);
    }

    // section{
    //   margin-bottom: 15px;
    // }
    // .md-field{
    //   label{
    //     color: black;
    //     font-weight: bold;
    //   }
    // }

    .features {
        text-align: left;
        .md-checkbox .md-checkbox-label,
        .md-radio .md-radio-label {
            top: 0!important;
        }
    }

    form {
        label {
            font-weight: bold;
        }
        .label {
            font-weight: bold;
            display: block;
        }
        .vertical-labels {
            flex-direction: column;
            // BUG: weird spacing issue that cuts off left border
            padding-left: 1px;
        }
        .md-helper-text {
            color: #bbb;
        }
        section.md-field {
            display: block;
            padding-top: 0;
        }
        .md-field {
            margin-bottom: 0;
            &.md-invalid {
                margin-bottom: 24px;
            }
        }
        .md-helper-text {
            display: block;
            margin-bottom: 18px;
        }
    }

    .submitContainer {
        padding: 0 0 20px;
        &.mobile {
            width: 100%;
            padding: 10px 0 30px;
            .v-btn {
                display: block;
                width: 100%;
            }
        }
        .v-btn {
            margin: 0 auto;
        }
    }

    .md-tabs {
        .md-tabs-navigation {
            background: #eee!important;
            .badge {
                position: absolute;
                background: #00c853;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                top: 34%;
                right: 30%;
            }
        }
        .md-tab {
            padding: 20px 2px 40px;
        }

        &.desktop {
            min-height: 75vh;
            .md-tabs-content {
                min-height: 75vh;
            }
            .md-field {
                margin-bottom: 30px;
            }
        }
    }

}
</style>
