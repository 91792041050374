<template>
<div class="">
  <label v-if="checkLocation || this.user.roleValue>50" aria-hidden="true" :class="{selectError:anyError}" class="v-input v-label v-label--active theme--light autocomplete-label" style="left: 0px; right: auto; position: absolute;">{{$t('uploads.select')}}</label>
  <GmapAutocomplete v-if="checkLocation || this.user.roleValue>50" @place_changed="setPlace" :class="{selectError:anyError}" @onchange="touched" class="v-input v-text-field autocomplete-input" :placeholder="$t('uploads.startTyping')" :select-first-on-enter="true">

  </GmapAutocomplete>
  <small :class="{selectError:anyError}">{{errors[0]}}</small>

  <GmapMap style="width:100%; height: 300px;" :zoom="12" :center="center">
    <GmapMarker label="★" :position="{
      lat: this.cLocation.lat,
      lng: this.cLocation.lng,
      }" v-if="this.cLocation!=undefined"/>
    </GmapMap>

</div>
</template>

<script>
// import * as VueGoogleMaps from 'vue2-google-maps'
// import Vue from 'vue'
// import config from '../../config'
//
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: config.GOOGLE_MAPS_API_KEY,
//     libraries: 'places', //// If you need to use place input
//   }
// })

export default {
  props:['errors','user','general', 'cLocation'],
  data: () => ({
    test:"taipei",
    center: {
      lat: 25.0330,
      lng: 121.5654
    },
    markers: [],
    place: null,

  }),
  description: 'Autocomplete Example (#164)',
  methods: {
    setDescription(description) {
      this.description = description
    },
    setPlace(place) {
      // console.log('setPlace');
      this.place = place

      console.log(place)
      const location = {
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      }
      this.center=location
      // console.log('location sent',location)
      this.$emit('setLocation',location)
    },
    touched(){
      console.log('oooh you touched my trlalala');
    },

  },
  computed:{
    checkLocation(){
      if (this.cLocation!=undefined && this.cLocation.lat!=0 && this.general.complete){
        return false
      } else {
        return true
      }
    },
    anyError(){
      if (this.errors.length>0) {
        console.log('there is error an error');
        return true
      } else {
        return false
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.gsearch {
  color: black;
  font-weight: bold;
  font-size: 12px;
}

input {
  padding: 8px 5px 8px 28px;
  border: 1px solid rgba(0, 0, 0, 0.6);
}

.icon {
  position: absolute;
  left: 4px;
}

.md-layout {
  position: relative;
}

.md-icon {
  height: 38px;
}

.md-helper-text {
  margin: 0 !important;
}

.address-help {
  color: rgba(0, 0, 0, .54);
  margin-left: 1em;
  font-size: 12px;
}
.v-input {
    padding:0px;

}
.selectError{
  color:red !important;
  border-color:red !important;
}
.selectError::placeholder{
  color:red!important
}
.autocomplete-label{
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  transform-origin: top left;
  white-space: nowrap;
  transform: translateY(-18px) scale(.75);
  width:100%;

}
</style>
