<template>
<div class="apartmentContainer">
  <Navbar></Navbar>
  <Loading v-if="initialLoad"></Loading>
  <v-layout  wrap justify-center v-else>
    <v-flex md9 xs11>
      <h1 class="text-sm-center">{{$t('uploads.apartment')}}

      </h1>
      <v-btn color="primary" flat href="https://youtube.com/watch?v=yrOZuHRuf9M" target="blank">{{$t('newListing.howToAddApt')}}</v-btn>
      <v-btn color="primary" flat :href="'/apartmentDetails/'+apartmentId+'/rooms'" target="blank" v-if="apartmentDraft==false && user.roleValue>40">See listing</v-btn>
      <v-tabs centered color="primary" show-arrows dark icons-and-text v-model="tabs">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab href="#tab-one">
          {{$t('uploads.apartment')}}
          <v-icon color="#ffffff">domain</v-icon>
          <v-badge v-if="apartment.general.complete" color="green">
            <v-icon slot="badge" color="#ffffff">done</v-icon>
          </v-badge>
        </v-tab>
        <v-tab href="#tab-two">
          {{$t('uploads.features')}}
          <v-icon>hotel</v-icon>
          <v-badge v-if="apartment.featureList.complete" color="green">
            <v-icon slot="badge">done</v-icon>
          </v-badge>
        </v-tab>
        <v-tab href="#tab-three">
          {{$t('uploads.contract')}}
          <v-icon>description</v-icon>
          <v-badge v-if="apartment.contractDetails.complete" color="green">
            <v-icon slot="badge">done</v-icon>
          </v-badge>
        </v-tab>
        <v-tab href="#tab-four">
          {{$t('uploads.photos')}}
          <v-icon>image</v-icon>
          <v-badge v-if="apartment.visual.video!=''" color="green">
            <v-icon slot="badge">done</v-icon>
          </v-badge>
        </v-tab>

      </v-tabs>
      <v-tabs-items class="tab-content" v-model="tabs">

        <v-tab-item id="tab-one">

          <form novalidate @submit.prevent="submitApt('general')">


            <div class="submitContainer md-size-100 md-layout-item" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-layout >
              <v-btn :disabled=" uploading || !$v.apartment.general.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
                {{$t('uploads.save')}}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="deleteAptDialog = true">{{$t('uploads.unlist')}}</v-btn>

              <v-dialog
                v-model="deleteAptDialog"
                max-width="400px"
              >
                <v-card>
                  <v-card-title class="headline">{{$t('uploads.unlistTitle')}}</v-card-title>
                  <v-card-text>
                    {{$t('uploads.unlistText')}}
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="success"
                      flat="flat"
                      @click="deleteAptDialog = false"
                    >
                      {{$t('uploads.keep')}}
                    </v-btn>

                    <v-btn
                      color="error"
                      flat="flat"
                      @click="deleteApt"
                    >
                      {{$t('uploads.unlist')}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </v-layout>
              <i class="md-helper-text" v-if="!$v.apartment.general.$anyDirty">
                {{$t('uploads.waiting')}}
              </i>
              <!-- show loading/submitting thing -->
              <div class="">
                <v-progress-circular indeterminate color="primary" v-if="uploading && !apartment.general.complete" :size="30" :width="3"></v-progress-circular>
              </div>
            </div>
            <!-- Apartment Title -->
            <v-text-field v-model="apartment.general.title" :error-messages="titleErrors" :label="$t('generalInfo.apartmentsTitle')" :hint="$t('generalInfo.aptTitleHint')" @input="$v.apartment.general.title.$touch()" @blur="$v.apartment.general.title.$touch()"></v-text-field>

            <!-- APARTMENT NAME -->
            <v-text-field v-model="apartment.general.name" :error-messages="nameErrors" :label="$t('generalInfo.apartmentName')" :hint="$t('generalInfo.nameHint')" required @input="$v.apartment.general.name.$touch()" @blur="$v.apartment.general.name.$touch()"></v-text-field>
            <!-- TEXT DESCRIPTION -->

            <v-textarea v-model="apartment.general.description" :error-messages="descriptionErrors" :label="$t('generalInfo.description')" :hint="$t('generalInfo.descriptionHint')" required :counter="500" @input="$v.apartment.general.description.$touch()" @blur="$v.apartment.general.description.$touch()"></v-textarea>

            <!-- LandlordSelect for ADMIN ONLY -->
            <v-autocomplete :loading="loading" :items="landlords" v-model="apartment.general.landlordId" hide-details clearable item-text="userName" item-value="id" label="Who's the landlord?" v-if="user.roleValue>20">
              <template slot="no-data">
                <v-list-tile>
                  <v-list-tile-title>
                    No landlord found!
                  </v-list-tile-title>
                </v-list-tile>
              </template>

              <template slot="selection" slot-scope="{item, selected}">
                <v-chip
                  :selected="selected"
                  color="primary"
                  class="white--text"
                >
                  <span v-text="item.userName +' ('+item.email+')'"></span>
                </v-chip>

              </template>
              <template
                slot="item"
                slot-scope="{ item, tile }"
              >
                <v-list-tile-avatar
                  color="indigo"
                  class="headline font-weight-light white--text"
                >
                  <!-- {{ item.userName.charAt(0) }} -->
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title v-text="item.userName"></v-list-tile-title>
                  <v-list-tile-sub-title v-text="item.email"></v-list-tile-sub-title>
                </v-list-tile-content>
              </template>
            </v-autocomplete>
            <!-- LOCATION -->
            <v-text-field
              :label="$t('generalInfo.floor')"
              type="number"
              :error-messages="floorError"
              v-model="apartment.general.floor"
              :hint="$t('generalInfo.floorHint')"
              @input="$v.apartment.general.floor.$touch()"
              @blur="$v.apartment.general.floor.$touch()"
            ></v-text-field>

            <div class="md-form-group autocomplete-group md-layout-item md-size-100">
              <!-- <UploadMap :cLocation="apartment.general.location" :general="apartment.general" :user="user" :errors="locationErrors" @setLocation="setLocation"></UploadMap> -->
              <UploadOSM  :cLocation="apartment.general.location" :general="apartment.general" :user="user" :errors="locationErrors" @setLocation="setLocation"></UploadOSM>
            </div>
            <v-layout  wrap>
              <v-spacer></v-spacer>
              <div class="submitContainer text-xs-right md-size-100 text-xs-right md-layout-item" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-btn :disabled=" uploading || !$v.apartment.general.$anyDirty" class="first-continue-button section-date md-raised md-primary md-layout-item" type="submit">
                {{$t('uploads.save')}}
              </v-btn>

                <i class="md-helper-text" v-if="!$v.apartment.general.$anyDirty">
                  {{$t('uploads.waiting')}}
                </i>
                <!-- show loading/submitting thing -->
                <div class="">
                  <v-progress-circular indeterminate color="primary" v-if="uploading && !apartment.general.complete" :size="30" :width="3"></v-progress-circular>
                </div>
              </div>
            </v-layout>
          </form>

        </v-tab-item>
        <v-tab-item id="tab-two">


          <form novalidate @submit.prevent="submitApt('featureList')">
            <div class="submitContainer md-size-100 md-layout-item" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-btn :disabled=" uploading || !$v.apartment.featureList.$anyDirty" class="first-continue-button" type="submit">
                {{$t('uploads.save')}}
              </v-btn>
              <i  class="md-helper-text" v-if="!$v.apartment.featureList.$anyDirty">
                {{$t('uploads.waiting')}}
            </i>
              <!-- show loading/submitting thing -->
              <div>
                <v-progress-circular indeterminate color="primary" v-if="uploading && !apartment.featureList.complete" :size="30" :width="3"></v-progress-circular>
              </div>
            </div>
            <Bathroom v-for="(bathroom, index) in $v.apartment.featureList.bathrooms.$model" :key="index" :user="user" :bathroom='bathroom' :disableRemove='false' :index='index' @removeBathroom="removeBathroom($event)" @bathroomUpdated="setBathroom($event, index)" />
            <div class="">
              <small v-if="bathroomErrors.length>0" class="err">{{bathroomErrors[0]}}</small>
            </div>
            <v-btn class="v-btn md-primary md-border" @click="addBathroom">
              {{$t('features.addBathroom')}}
            </v-btn>
            <v-select
              :items="kitchenOptions"
              v-model="$v.apartment.featureList.kitchenType.$model"
              :label="$t('features.kitchen')"
              :error-messages="kitchenErrors"
            ></v-select>
            <v-text-field
              :label="$t('features.bedrooms')"
              :hint="$t('features.bedroomsHint')"
              type="number"
              :error-messages="bedroomsError"
              v-model="apartment.featureList.numberOfBedrooms"
              @input="$v.apartment.featureList.numberOfBedrooms.$touch()"
              @blur="$v.apartment.featureList.numberOfBedrooms.$touch()"
            ></v-text-field>
            <v-layout  wrap>
              <v-flex xs12>

              <span class="v-label">{{$t('features.commonArea')}}</span>
            </v-flex>
            <v-flex xs12>

              <v-checkbox label="Cleaned by a professional BEFORE the arrival of each tenant" v-model="apartment.featureList.cleaned" @change="$v.apartment.featurList.features.$touch()"></v-checkbox>
            </v-flex>

              <v-flex xs4 v-for="(feature,index) in possibleFeatures" :key="index" :value="index">
                <v-checkbox v-model="apartment.featureList.features" multiple color="primary" :value="index" @change="$v.apartment.featureList.features.$touch()">
                  <template slot="label">
                    <span><span :class="feature.icon"></span> {{$t('features.'+index)}}</span>
                  </template>

                </v-checkbox>

              </v-flex>
            </v-layout>
            <v-layout  wrap>
            <v-btn color="secondary" flat @click="back">{{$t('uploads.back')}}</v-btn>

              <v-spacer></v-spacer>
            <div class="submitContainer text-xs-right md-size-100 md-layout-item" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
              <v-btn :disabled=" uploading || !$v.apartment.featureList.$anyDirty" class="first-continue-button" type="submit">
                {{$t('uploads.save')}}
              </v-btn>
              <i  class="md-helper-text" v-if="!$v.apartment.featureList.$anyDirty">
              {{$t('uploads.waiting')}}
            </i>
              <!-- show loading/submitting thing -->
              <div>
                <v-progress-circular indeterminate color="primary" v-if="uploading && !apartment.featureList.complete" :size="30" :width="3"></v-progress-circular>
              </div>
            </div>
          </v-layout>

          </form>

        </v-tab-item>
        <v-tab-item id="tab-three">
          <form novalidate @submit.prevent="submitApt('contractDetails')">
          <div class="submitContainer md-size-100 md-layout-item" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
            <v-btn :disabled=" uploading || !$v.apartment.contractDetails.$anyDirty" class="first-continue-button" type="submit">
              {{$t('uploads.save')}}
            </v-btn>
            <i  class="md-helper-text" v-if="!$v.apartment.contractDetails.$anyDirty">
            {{$t('uploads.waiting')}}
          </i>
            <!-- show loading/submitting thing -->
            <div>
              <v-progress-circular indeterminate color="primary" v-if="uploading && !apartment.contractDetails.complete" :size="30" :width="3"></v-progress-circular>
            </div>
          </div>
          <v-select
            :items="contractLengthOptions"
            v-model="$v.apartment.contractDetails.contractLength.$model"
            :label="$t('contractDetails.minLength')"
            :error-messages="cLengthError"
            :hint="$t('contractDetails.minLengthHint')"
          >
          </v-select>
          <v-select
            :items="contractFlexibilityOptions"
            v-model="$v.apartment.contractDetails.contractFlexibility.$model"
            :label="$t('contractDetails.contractFlexibility')"
            :hint="$t('contractDetails.contractFlexibilityHint')"
            :error-messages="cFlexError"
          >
          </v-select>
          <v-select
            :items="contractLanguageOptions"
            v-model="$v.apartment.contractDetails.contractLanguage.$model"
            :label="$t('contractDetails.contractLanguage')"
            :error-messages="clangError"
            :hint="$t('contractDetails.contractLanguageHint')"
          ></v-select>
          <v-layout  wrap>
          <v-flex xs12>
            <div class="title">
              {{$t('contractDetails.rules')}}
            </div>
          </v-flex>
          <v-flex xs4 v-for="(rule,index) in apartment.contractDetails.rules" :key="'rule_'+index">
          <v-switch  label="rule.name" color="primary" v-model="rule.value" @change="$v.apartment.contractDetails.rules.$touch()">
            <template slot="label">
              <span><span :class="'MRAIcon_'+index"></span> {{$t('contractDetails.rulesTexts.'+index)}}</span>
            </template>
          </v-switch>

        </v-flex>
      </v-layout>
      <v-textarea v-model="apartment.contractDetails.otherRules" :label="$t('contractDetails.otherRules')" :rows="2" :hint="$t('contractDetails.otherRulesHint')" @input="$v.apartment.contractDetails.otherRules.$touch()" @blur="$v.apartment.contractDetails.otherRules.$touch()">
      </v-textarea>
      <v-select
        :items="utilitiesOptions"
        v-model="apartment.contractDetails.utilities"
        :error-messages="utilitiesError"
        :label="$t('billing.utilities')"
        :hint="$t('billing.utilitiesHint')"
        @change="setUtilitiesOptions"
      ></v-select>
      <v-text-field
        type="number"
        v-model="apartment.contractDetails.utilitiesAmount"
        suffix="NT$"
        :label="$t('billing.utilitiesAmount')"
        v-if="showUtilAmount"
        @input="$v.apartment.contractDetails.utilitiesAmount.$touch()"
        @blur="$v.apartment.contractDetails.utilitiesAmount.$touch()"
      ></v-text-field>
      <v-textarea
        v-model="apartment.contractDetails.utilitiesComment"
        :label="$t('billing.utilitiesComment')"
        rows="2"
        v-if="showUtilComment"
        :hint="$t('billing.utilitiesCommentHint')"
        @input="$v.apartment.contractDetails.utilitiesComment.$touch()"
        @blur="$v.apartment.contractDetails.utilitiesComment.$touch()"
      ></v-textarea>
      <v-layout  wrap>
      <v-btn color="secondary" flat @click="back">{{$t('uploads.back')}}</v-btn>

      <v-spacer></v-spacer>
      <div class="submitContainer text-xs-right md-size-100 md-layout-item" :class="{'mobile':$mq == 'sx' || $mq == 'sm'}">
        <v-btn :disabled=" uploading || !$v.apartment.contractDetails.$anyDirty" class="first-continue-button" type="submit">
          {{$t('uploads.save')}}
        </v-btn>
        <i  class="md-helper-text" v-if="!$v.apartment.contractDetails.$anyDirty">
        {{$t('uploads.waiting')}}
      </i>
        <!-- show loading/submitting thing -->
        <div>
          <v-progress-circular indeterminate color="primary" v-if="uploading && !apartment.contractDetails.complete" :size="30" :width="3"></v-progress-circular>
        </div>
      </div>
    </v-layout>
    </form>
        </v-tab-item>
        <v-tab-item id="tab-four">
          <section>
            <div class="title">{{$t('visual.showTenants')}}</div>
            <div class="subheading">{{$t('visual.addVideo')}}</div>
            <v-text-field
              v-model="apartment.visual.video"
              dont-fill-mask-blanks
              browser-autocomplete="disable"
              :label="$t('visual.video')"
              :hint="$t('visual.videoHint')"
              @change="addYoutubeLink"
            ></v-text-field>
            <Dropzone id="photos" @onUpload="uploadPhotos" @onSorted="sortedPhotos" :photos="apartment.visual.photos" :apartment="true" :tag="tagOptions" :landlordId="user.id" @addPhoto='addPhoto($event,"Room")'>
            </Dropzone>
          </section>
          <v-layout  wrap>
            <v-btn color="secondary" flat @click="back">{{$t('uploads.back')}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="finish">{{$t('uploads.finish')}}</v-btn>
          </v-layout>

        </v-tab-item>
      </v-tabs-items>

    </v-flex>

  </v-layout>
  <v-snackbar
    v-model="showSnackbar"
    color="success"
    :timeout="6000"

  >
    {{snackbarMessage}}
    <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
  </v-snackbar>
  <v-dialog
       v-model="showAptDialog"
       width="600"
     >
       <v-card>
         <v-card-title class="headline">{{$t('uploads.newApartmentTitle')}}</v-card-title>

         <v-card-text>
           <div class="">
             {{$t('uploads.newApartmentExplanation1')}}
           </div>
           <div class="">
             {{$t('uploads.newApartmentExplanation2')}}
           </div>
           <div class="">
             {{$t('uploads.newApartmentExplanation3')}}
           </div>
           <div class="">
             {{$t('uploads.newApartmentExplanation4')}}
           </div>
           <div class="">
             {{$t('uploads.newApartmentExplanation5')}} <a href="/dashboard"> {{$t('uploads.linkNewApt')}}</a>
           </div>
         </v-card-text>
         <v-card-actions>
           <v-checkbox :label="$t('uploads.noShow')" v-model="stopShowing"></v-checkbox>
           <v-spacer></v-spacer>

           <v-btn
             color="primary"
             flat
             @click="closeNewAptDialog"
           >
           {{$t('uploads.close')}}
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
</div>
</template>
<script>
import axios from 'axios'
import UploadOSM from './UploadOSM.vue'
import Dropzone from '../Dropzone.vue'
const UploadMap = ()=> import (/* webpackChunkName: "add-listing-group" */ './UploadMap.vue')
const Bathroom = ()=> import (/* webpackChunkName: "add-listing-group" */ './Bathroom.vue')

// import UploadMap from './UploadMap.vue'
// import Bathroom from './Bathroom.vue'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import draggable from 'vuedraggable'
import Loading from '../shared/Loading.vue'
// import AWSSES from '../../aws-ses'
// import moment from 'moment'
import {
  // not,
  // sameAs,
  minValue,
  required,
  minLength,
  maxLength,
  // between,
  // helpers,
} from 'vuelidate/lib/validators'
// AWSSES.emailing(userData, 'accountDeleted')


export default {

  data: () => ({
    tabs: "tab-one",
    showSnackbar: false,
    initialLoad:true,
    showUtilComment:true,
    snackbarMessage: "",
    landlordsObj:{},
    deleteAptDialog:false,
    showAptDialog:false,
    possibleFeatures: {
      wifi: {
        value: false,
        icon: 'MRAIcon_wifi',
        name: 'Wifi'
      },
      washingMachine: {
        value: false,
        icon: 'MRAIcon_laundry',
        name: 'Washing Machine'
      },
      tv: {
        value: false,
        icon: 'MRAIcon_tv',
        name: 'TV'
      },
      couch: {
        value: false,
        icon: 'MRAIcon_couch',
        name: 'Couch'
      },
      coffeeTable: {
        value: false,
        icon: 'MRAIcon_coffee_table',
        name: 'Coffee Table'
      },
      balcony: {
        value: false,
        icon: 'MRAIcon_balcony',
        name: 'Balcony'
      },
      rooftop: {
        value: false,
        icon: 'MRAIcon_rooftop',
        name: 'Rooftop'
      },
      elevator: {
        value: false,
        icon: 'MRAIcon_elevator',
        name: 'Elevator'
      },
      security: {
        value: false,
        icon: 'MRAIcon_security',
        name: 'Security'
      },
      cleaningService: {
        value: false,
        icon: 'MRAIcon_cleaning',
        name: 'Cleaning Service'
      },
      trashService: {
        value: false,
        icon: 'MRAIcon_trash_service',
        name: 'Trash Service'
      }
    },
    apartment: {
      verifiedApartment: false,
      lastModified: new Date(),
      general: {
        landlordId: '',
        description: "",
        name: "",
        location: {
          lat: 0,
          lng: 0,
        },
        dateAdded: new Date(),
        complete: false,
      },
      featureList: {
        cleaned:false,
        complete: false,
        bathrooms: [{
          type: ''
        }],
        kitchenType: "",
        numberOfBedrooms: 1,
        features: ['wifi', 'washingMachine'],
      },
      contractDetails:{
        complete:false,
        otherRules: "",
        rules: {
          noSmoking: {
            name: 'No Smoking',
            value: true
          },
          noPets: {
            name: 'No pets allowed',
            value: true,
          },
          girlsOnly: {
            name: 'Girls only',
            value: false
          },
          noParties: {
            name: 'No Parties - Strict Noise Policy',
            value: false
          },
          noGuests: {
            name: 'No overnight guests allowed',
            value: false,
          },
          extensiveIntro: {
            name: 'Extensive introduction required',
            value: false,
          },
          noSublease: {
            name: 'No sublease allowed',
            value: false,
          }
        },
        contractLanguage: 'English',
        contractLength: '1 month',
        contractFlexibility: '',
        utilities: 'Included in rent',
        utilitiesAmount: '0',
        utilitiesComment: '',

      },
      visual:{
        photos:[],
        video:"",
      }
    },
    apartmentDraft:true,
    landlords: [],
    uploading: false,
    errorMessage: "",
    apartmentId: "",
    loading: false,
    admin: {
      email: 'hello@myroomabroad.com',
      name: 'Admin',
    },
    stopShowing:false,
  }),

  validations: {
    apartment: {
      general: {
        title:{
          required
        },
        description: {
          required,
          maxlength: maxLength(500),
        },
        name: {
          required,
          nonChinese(name){
            return(/^[\x20-\x7E]*$/.test(name))
          }
        },
        landlordId: {
          required
        },
        floor:{
          required
        },
        location:
        {
          lat:{
            required,
            minValue: minValue(1)
        },
          lng:{
            required,
            minValue: minValue(1)
          },
        },
      },
      featureList: {
        bathrooms: {
          required,
          minLength: minLength(1)
        },
        kitchenType: {
          required
        },
        numberOfBedrooms:{
          required,
        },
        features:{
        }
      },
      contractDetails:{
        contractLength:{
          required
        },
        contractFlexibility:{
          required
        },
        contractLanguage:{
          required
        },
        rules:{
        },
        otherRules:{
        },
        utilities:{
          required
        },
        utilitiesAmount:{

        },
        utilitiesComment:{
        },
      },
    }
  },
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 150,
        behavior: "smooth"
      })
    },
    setLocation(payload){
      // this.apartment.general.location={}
      console.log('the apartment location exists:', this.apartment.general.location.lat, this.apartment.general.location.lng)
      console.log('setLocation', payload.lat, payload.lng)
      this.$v.apartment.general.location.lat.$model= payload.lat
      this.$v.apartment.general.location.lng.$model= payload.lng
      this.$v.apartment.general.$touch()
      console.log('setLocation', this.$v.apartment.general.location.lat.$model, this.$v.apartment.general.location.lng.$model);

      this.$v.apartment.general.$touch()
      console.log('the $v', this.$v.apartment.general);
    },
    closeNewAptDialog(){
      console.log('close dialog');
      this.showAptDialog=false
      if (this.stopShowing){
        console.log('close dialog inside if');
        axios.patch('/users/'+this.user.id+'.json?auth=' + this.$store.state.auth.idToken,{aptOk:true})
      }
    },
    setUtilitiesOptions(payload){
      this.$v.apartment.contractDetails.utilities.$touch()
      console.log(payload)
      switch (payload) {
        case 'Included in rent':
          this.apartment.contractDetails.utilitiesAmount=0
          break;
        case 'Fixed monthly amount':
          break;
        case 'Split by tenants':
          this.apartment.contractDetails.utilitiesAmount=0
          break;
        case 'Other':
          this.apartment.contractDetails.utilitiesAmount=0
          break;

        default:

      }
    },

    uploadPhotos(x) {
      // console.log('photos uploaded -->', x);
      if(!this.apartment.visual.hasOwnProperty('photos')){
        this.apartment.visual.photos=[]
      }
      this.apartment.visual.photos.push(x);
      if(this.apartment.visual.photos.length>4){
        this.apartment.visual.complete=true
      }
      this.updateApt('visual', this.apartment.visual);
    },
    sortedPhotos(x) {
      // console.log('photos uploaded -->', x);
      this.apartment.visual['photos'] = x;
      this.updateApt('visual', this.apartment.visual);
    },
    addYoutubeLink(){
      if(this.apartment.visual.video.includes('youtube')){
        // console.log('upload');
        this.updateApt('visual', this.apartment.visual);
      } else {
        alert('No "youtube" in link, please insert the valid url')
      }
    },
    addBathroom() {
      // console.log('add a bathroom')
      // console.log(this.$v.apartment.$model);
      if (!this.apartment['featureList'].hasOwnProperty('bathrooms')) {
        // console.log('there si no bathroom prop');
        this.$v.apartment.featureList.$model = {
          'bathrooms': []
        };
      }

      this.$v.apartment['featureList']['bathrooms'].$model.push({
        type: 'Wet Bathroom',
      });
      this.$v.apartment['featureList']['bathrooms'].$touch();
    },
    removeBathroom(e) {
      // console.log('remove '+e)
      this.$v.apartment['featureList']['bathrooms'].$model.splice(e, 1);
      this.$v.apartment['featureList']['bathrooms'].$touch();
    },
    setBathroom(e, i) {
      // console.log('bathroom', e, i);
      // console.log(typeof this.$v.apartment.featureList.bathrooms.$model);
      // this.apartment.featureList.bathrooms[i] = e;
      if (!this.$v.apartment.featureList.bathrooms.$model == "object") {
        // if(typeof this.$v.apartment.featureList.hasOwnProperty('bathrooms')){
        this.$v.apartment.featureList.bathrooms.$model = [e]
      } else {

        this.$v.apartment.featureList.bathrooms.$model[i] = e;
      }
      this.$v.apartment['featureList']['bathrooms'].$touch();
    },

    deleteApt(){
      // if(!this.apartmentDraft){

        axios.get('apartments/'+this.apartmentId+'/rooms.json')
        .then(res=>{
          this.apartment.rooms=res.data
          for (var room in this.apartment.rooms) {
            if (this.apartment.rooms.hasOwnProperty(room)&& room!="") {
              axios.patch('rooms/'+room+'.json?auth=' + this.$store.state.auth.idToken,{draft:true})
              .catch(err=>{
                console.log('error: ',err);
              })
            }
          }
        })
        .catch(err=>{
          console.log('error: ',err);
        })
        .finally(()=>{
          this.$router.push({name:'LandlordDashboard'})
        })
      // } else {
      //   if (this.apartmentId instanceof String){

          // axios.delete('apartments/'+this.apartmentId+'.json?auth='+this.$store.state.auth.idToken)
          // .then(() => {
          //   console.log('successfully deleted from apartments/')
          //   axios.get('users/'+this.apartment.general.landlordId+'/apartments.json')
          //   .then(res=>{
          //     console.log('usr apartments: ',res.data)
          //     let apts=res.data
          //     for (var apartment in apts) {
          //       if (apts.hasOwnProperty(apartment)) {
          //         // console.log('compare: ' , apts[apartment].apartmentId,this.apartmentId)
          //         if (apts[apartment].apartmentId==this.apartmentId)
          //         // console.log('delete this one: ',apartment);
          //         axios.delete('users/'+this.apartment.general.landrlodId+'/apartments/'+apartment+'.json/?auth='+this.$store.state.auth.idToken)
          //         .then(()=>{
          //           // console.log('successfully deleted from users/apartments')
          //           this.$router.push({name:'LandlordDashboard'})
          //         })
          //         .catch(err=>{
          //           console.log('error: ',err);
          //         })
          //
          //       }
          //     }
          //   })
          //   .catch(err=>{
          //     console.log('error: ',err);
          //   })
          //
          // })
          // .catch(err=>{
          //   console.log('error: ',err);
          // })
      //   }
      // }


    },



    /*****
    Validation stuffs
    *******/

    touchField(section) {
      // console.log('touch field...', section, field);
      this.$v.apartment[section].$touch();
    },

    //setting the class on the field to show errors
    getValidationClass(section, fieldName) {
      // console.log('get validation class');
      // const vsection = this.$v.apartment[section]
      const field = this.$v.apartment[section][fieldName]
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    //validating the section, if valid then submit to server
    validateSection(section) {
      // console.log('validate section');
      this.$v.apartment[section].$touch()
      if (!this.$v.apartment[section].$invalid && !this.$v.apartment[section].$anyError) {
        // console.log("Valid");
        return true;
      } else {
        // console.log('Error');
        return false;
      }
    },
    back(){
      switch (this.tabs) {
        case 'tab-four':
          this.tabs='tab-three'
          this.scrollTo()
          break;
        case 'tab-three':
          this.tabs="tab-two"
          this.scrollTo()
          break
        case 'tab-two':
          this.tabs="tab-one"
          this.scrollTo()
          break
      }
    },


    /**************
      Submit Section
    **************/


    checkOtherForms(){
      if (this.apartment.general.complete && this.apartment.featureList.complete && this.apartment.contractDetails.complete && this.apartment.visual.complete){
        if (this.apartment.visual.photos&& this.apartment.visual.photos.length>0){
          this.apartmentDraft=false
        }
      } else {
        this.apartmentDraft=true
      }
    },
    submitApt(section) {
      this.validateSection(section) ? this.updateApt(section, this.apartment[section]) : null;
    },
    updateApt(formName, formData) {
      console.log('updateApt');
      let vThis = this;
      let object = {}
      formData['complete'] = true;
      object[formName] = formData;
      this.checkOtherForms()
      if(!this.apartmentDraft){
        object['draft']=false
      }
      console.log('set trust');
      if (this.apartment.general.landlordId==this.user.id){
        object['llTrustVal']=this.user.trustVal
        object['trustVal']=this.user.trustVal
      } else {
        object['llTrustVal']=this.landlordsObj[this.apartment.general.landlordId].trustVal
        object['trustVal']=this.landlordsObj[this.apartment.general.landlordId].trustVal
      }
      // TODO is this necessary?
      object['lastModified'] = new Date()
      this.uploading = true
      axios.patch('/apartments/' + this.apartmentId + '.json' + '?auth=' + this.$store.state.auth.idToken, object)
        .then(() => {
          // console.log('patch of apartment success')
          if(vThis.originalLandlord!=this.apartment.general.landlordId){
            axios.get('/users/'+vThis.originalLandlord+'/apartments.json')
              .then(res => {
                // console.log('RES:');
                // console.log(res)
                for (var apartment in res.data) {
                  if (res.data.hasOwnProperty(apartment)) {
                    if (res.data[apartment].apartmentId===vThis.apartmentId){
                        axios.delete('/users/'+vThis.originalLandlord+'/apartments/'+apartment+'.json'+ '?auth=' + vThis.$store.state.auth.idToken)
                        .catch(err=>{
                          console.log(err);
                        })
                        axios.post('/users/'+vThis.apartment.general.landlordId+'/apartments.json' + '?auth=' + vThis.$store.state.auth.idToken, {
                          apartmentId:vThis.apartmentId
                        })
                        .catch(err=>{
                          console.log(err);
                        })

                    }
                  }
                }
              })
              .catch(err=>{
                console.log(err);
              })
            }
          })
          .catch(err=>{
            console.log(err);
          })
          .finally(()=>{
            vThis.uploading=false
            vThis.draft=false
            vThis.cleanUpPosting();
            switch (formName) {
              case 'general':
                this.tabs='tab-two'
                this.scrollTo()
                break;
              case 'featureList':
                this.tabs="tab-three"
                this.scrollTo()
                break
              case 'contractDetails':
                this.tabs="tab-four"
                this.scrollTo()
                break
            }

          })
    },

    cleanUpPosting(){
      // console.log('snackba should be there')
      this.snackbarMessage = this.$t('uploads.snackbarMessage')
      this.showSnackbar = true;
      this.uploading = false;
      this.complete = true;
      //closes the snackbar
      setTimeout(function(){
           this.complete = false;
       }, 4000);
       this.$v.$reset();
    },

    finish() {
      // console.log('finish' , this.apartment.general.landlordId, this.user.id)
      if (this.apartment.general.landlordId!=this.user.id){
        this.$router.push({
          name:'adminLandlordDashboard',
          params:{
            id:this.apartment.general.landlordId
          }
        })
      } else {
        this.$router.push({
          name: 'LandlordDashboard'
        })
      }
    }
  },

  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    let vThis=this
    if(this.draft==true){
      axios.get('/users/'+vThis.originalLandlord+'/apartments.json')
        .then(res => {
          // console.log('RES:');
          // console.log(res)
          for (var apartment in res.data) {
            if (res.data.hasOwnProperty(apartment)) {
              if (res.data[apartment].apartmentId===vThis.apartmentId){
                  // console.log('you found it')
                  // console.log(apartment)
                  axios.delete('/users/'+vThis.originalLandlord+'/apartments/'+apartment+'.json' + '?auth=' + vThis.$store.state.auth.idToken)
                    .catch(err=>{
                      console.log(err);
                    })
                  axios.delete('/apartments/'+vThis.apartmentId+'.json' + '?auth=' + vThis.$store.state.auth.idToken)
                  .catch(err=>{
                    console.log(err);
                  })

              }
            }
          }
        })
        .catch(err=>{
          console.log(err);
        })
        .finally(()=>{
          next()
        })
    } else {
      next()
    }
  },

  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    showUtilAmount(){
      if (this.apartment!=undefined && this.apartment.contractDetails!=undefined &&this.apartment.contractDetails.utilities=="Fixed monthly amount"){
        return true
      } else {
        return false
      }
    },
    kitchenOptions:function(){
      let t=[
        'None',
        'Basic cooking facilities',
        'Fully equiped kitchen'
      ]
      if (this!=undefined){
        t=[{
            value: 'None',
            text: this.$t('features.kitchenOptions.none'),
          },
          {
            value: 'Basic cooking facilities',
            text: this.$t('features.kitchenOptions.basic'),
          },
          {
            value: 'Fully equiped kitchen',
            text: this.$t('features.kitchenOptions.full'),
          }]
      }
      return t
    },
    contractLengthOptions: function() {
      let t = ['1 month',
        '3 months',
        '5 months',
        '12 months',
        'Open contract'
      ]
      if (this != undefined) {
        t = [{
            value: '1 month',
            text: this.$t('contractDetails.minLengthOptions.one'),
          },
          {
            value: '3 months',
            text: this.$t('contractDetails.minLengthOptions.three'),
          },
          {
            value: '5 months',
            text: this.$t('contractDetails.minLengthOptions.five'),
          },
          {
            value: '12 months',
            text: this.$t('contractDetails.minLengthOptions.twelve'),
          },
          {
            value: 'Open contract',
            text: this.$t('contractDetails.minLengthOptions.open'),
          }
        ]
      }
      return t
    },

    contractFlexibilityOptions: function() {
      let t = [
        'Starts and ends any day',
        'Starts any day for 1 month',
        'Starts first or 15th',
        'Starts always on the first',
      ]
      if (this != undefined) {
        t = [{
            value: 'Starts and ends any day',
            text: this.$t('contractDetails.contractFlexibilityOptions.any'),
          },
          {
            value: 'Starts any day for 1 month',
            text: this.$t('contractDetails.contractFlexibilityOptions.oneMonth'),
          },
          {
            value: 'Starts first or 15th',
            text: this.$t('contractDetails.contractFlexibilityOptions.firstOr'),
          },
          {
            value: 'Starts always on the first',
            text: this.$t('contractDetails.contractFlexibilityOptions.first'),
          }
        ]
      }
      return t

    },

    contractLanguageOptions: function() {
      let t = [
        'English',
        'English & Chinese',
        'Chinese Only',
      ]
      if (this != undefined) {
        t = [{
            value: "English",
            text: this.$t('contractDetails.contractLanguageOptions.english'),
          },
          {
            value: "English & Chinese",
            text: this.$t('contractDetails.contractLanguageOptions.both'),
          },
          {
            value: "Chinese Only",
            text: this.$t('contractDetails.contractLanguageOptions.chinese'),
          }
        ]
      }
      return t

    },

    utilitiesOptions: function() {
      let t = [
        'Included in rent',
        'Fixed monthly amount',
        'Split by tenants',
        'Other'
      ]
      if (this != undefined) {
        t = [{
            value: "Included in rent",
            text: this.$t('billing.utilitiesOptions.included'),
          },
          {
            value: "Fixed monthly amount",
            text: this.$t('billing.utilitiesOptions.fixed'),
          },
          {
            value: "Split by tenants",
            text: this.$t('billing.utilitiesOptions.split'),
          },
          {
            value: "Other",
            text: this.$t('billing.utilitiesOptions.other'),
          }
        ]
      }
      return t
    },
    tagOptions: function() {
      let a=['Common Area']
      if (this.apartment.featureList.kitchenType != 'None'){
        a.push(this.$t('Kitchen'))
      }
      if(this.apartment.featureList.bathrooms!=undefined){
        for (var i = 0; i < this.apartment.featureList.bathrooms.length; i++) {
          let item=""
          switch (this.apartment.featureList.bathrooms[i].type) {
            case 'Wet Bathroom':
              item='Bathroom '+ (i+1)+" (Wet)"
              break;
            case 'Dry Bathroom':
              item='Bathroom '+ (i+1)+" (Dry)"
              break;
            default:
              item='Bathroom ' + (i+1)
          }
          a.push(item)

        }
      }

      return a
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    titleErrors() {
      const errors = []
      if (!this.$v.apartment.general.title.$dirty) return errors
      !this.$v.apartment.general.title.required && errors.push(this.$t('generalInfo.titleHint'))
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.apartment.general.name.$dirty) return errors
      !this.$v.apartment.general.name.required && errors.push(this.$t('generalInfo.nameHint'))
      !this.$v.apartment.general.name.nonChinese && errors.push(this.$t('auth.userNameRegexErr'))
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.apartment.general.description.$dirty) return errors
      !this.$v.apartment.general.description.maxlength && errors.push('Restrict yourself to 500 characters')
      !this.$v.apartment.general.description.required && errors.push(this.$t('generalInfo.descriptionHint'))
      return errors
    },
    locationErrors(){
      const errors = []
      console.log('set locationErr');
      if (!this.$v.apartment.general.location.$dirty){
        return errors
      } else if (this.$v.apartment.general.location.$anyError || this.$v.apartment.general.location.$invalid){
        errors.push(this.$t('uploads.select'))
      }
      return errors
    },
    floorError(){
      const errors = []
      if (!this.$v.apartment.general.floor.$dirty) return errors
      !this.$v.apartment.general.floor.required && errors.push(this.$t('generalInfo.floorHint'))
      return errors
    },
    landlordErrors() {
      const errors = []
      if (!this.$v.apartment.general.landlordId.$dirty) return errors
      !this.$v.apartment.general.landlordId.required && errors.push('Landlord is required')
      return errors
    },
    bathroomErrors(){
      const errors = []
      if (!this.$v.apartment.featureList.bathrooms.$dirty) return errors
      !this.$v.apartment.featureList.bathrooms.minLength && errors.push('Add at least 1 bathroom')
      !this.$v.apartment.featureList.bathrooms.required && errors.push('Bathroom is required')
      return errors
    },
    kitchenErrors() {
      const errors = []
      if (!this.$v.apartment.featureList.kitchenType.$dirty) return errors
      !this.$v.apartment.featureList.kitchenType.required && errors.push('Please fill out the type of kitchen you have.')
      return errors
    },
    bedroomsError(){
      const errors = []
      if (!this.$v.apartment.featureList.numberOfBedrooms.$dirty) return errors
      !this.$v.apartment.featureList.numberOfBedrooms.required && errors.push(this.$t('features.bedroomsHint'))
      return errors
    },
    cLengthError(){
      const errors = []
      if (!this.$v.apartment.contractDetails.contractLength.$dirty) return errors
      !this.$v.apartment.contractDetails.contractLength.required && errors.push(this.$t('contractDetails.minLengthHint'))
      return errors
    },
    cFlexError(){
      const errors = []
      if (!this.$v.apartment.contractDetails.contractFlexibility.$dirty) return errors
      !this.$v.apartment.contractDetails.contractFlexibility.required && errors.push(this.$t('contractDetails.contractFlexibilityHint'))
      return errors
    },
    clangError(){
      const errors = []
      if (!this.$v.apartment.contractDetails.contractLanguage.$dirty) return errors
      !this.$v.apartment.contractDetails.contractLanguage.required && errors.push(this.$t('contractDetails.contractLanguageHint'))
      return errors
    },
    utilitiesError(){
      const errors = []
      if (!this.$v.apartment.contractDetails.utilities.$dirty) return errors
      !this.$v.apartment.contractDetails.utilities.required && errors.push(this.$t('contractDetails.utilitiesHint'))
      return errors
    }
  },
  created() {
    console.log('START CREATE NEW APT')
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    if (this.user.aptOk){
      this.showAptDialog=false
    } else {
      this.showAptDialog=true
    }
    let vThis = this;
    this.apartment.general.landlordId=vThis.$store.state.auth.user.id
    this.originalLandlord=this.apartment.general.landlordId
    //save new apartment as a draft
    // TODO make a pop up for leaving to remove this
    if (vThis.$route.query.apartmentId == undefined) {
      this.draft=true
      this.initialLoad=false
      axios.post('/apartments.json' + '?auth=' + vThis.$store.state.auth.idToken, {
          'draft': true,
          'general':{
            landlordId:vThis.$store.state.auth.user.id,
            complete:false,
            dateAdded:new Date(),
          },
        })
        .then((res) => {
          vThis.apartmentId = res.data.name
          vThis.originalLandlord=vThis.apartment.general.landlordId
          axios.post('/users/'+vThis.$store.state.auth.user.id+'/apartments.json' + '?auth=' + vThis.$store.state.auth.idToken, {
            'apartmentId':vThis.apartmentId
          })
          .catch(err=>{
            console.log(err);
          })
        })
        .catch(err=>{
          console.log(err);
        })

    }




    if (this.$route.query.apartmentId) {
      console.log('THERE IS AN ID');
      this.apartmentId = this.$route.query.apartmentId
      axios.get('/apartments/' + this.apartmentId + '.json')
        .then(res => {
          console.log('ID: ',this.user.id, res.data.general.landlordId, this.user.roleValue,);
          if (this.user.id === res.data.general.landlordId || this.user.roleValue > 50) {
            this.apartmentDraft = res.data.draft
            res.data.general ? vThis.apartment['general'] = res.data.general : null;
            res.data.featureList ? vThis.apartment['featureList'] = res.data.featureList : null;
            res.data.contractDetails ? vThis.apartment['contractDetails'] = res.data.contractDetails : null;
            res.data.visual ? vThis.apartment['visual'] = res.data.visual : null;
            vThis.originalLandlord=vThis.apartment.general.landlordId
            vThis.initialLoad=false
            this.setUtilitiesOptions()
            if (vThis.apartment.general.location==undefined){
              console.log('set location to null');
              vThis.apartment.general.location={
                  lat:0,
                  lng:0,
              }
            }
          } else {
            this.$router.push({
              name: 'homepage'
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
    if (this.$store.state.auth.user.roleValue > 50) {
      axios.get('/users.json?orderBy="roleValue"&startAt(20)')
        .then(res => {
          for (var landlord in res.data) {
            this.landlordsObj=res.data
            if (res.data.hasOwnProperty(landlord)) {
              let ll = res.data[landlord]
              ll.id = landlord
              this.landlords.push(ll)
            }
          }
        })
        .catch(err=>{
          console.log(err);
        })
    }
  },
  components: {
    Dropzone,
    Bathroom,
    draggable,
    Loading,
    UploadOSM,
  },

}
</script>
<style lang="scss" scoped>
.apartmentContainer {
    .v-tabs {
        .v-badge {
            margin-bottom: 0;
            i {
                margin-bottom: 0 !important;
            }
        }
    }
    .v-tabs__items {
        margin-top: 1em;
    }
    .autocomplete-group {
        padding-top: 24px;
    }
    .features {
        text-align: left;
    }
    .md-disabled {
        color: rgba(0,0,0,.38);
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;
        margin-top: 0;
        padding: 0;
        display: block;
        float: right;
        font-size: 20px;
    }

    .icon-delete-btn :hover {
        cursor: pointer;
        background-color: rgba(54, 183, 254, 1);
    }
    .features {
        text-align: left;
        .md-checkbox .md-checkbox-label,
        .md-radio .md-radio-label {
            top: 0!important;
        }
    }

    form {
        label {
            font-weight: bold;
        }
        .label {
            font-weight: bold;
            display: block;
          }
        .vertical-labels {
            flex-direction: column;
            // BUG: weird spacing issue that cuts off left border
            padding-left: 1px;
        }
        .md-helper-text {
            color: #bbb;
        }
        section.md-field {
            display: block;
            padding-top: 0;
        }
        .md-field {
            margin-bottom: 0;
            &.md-invalid {
                margin-bottom: 24px;
            }
        }
        .md-helper-text {
            display: block;
            margin-bottom: 18px;
        }
    }

    .submitContainer {
        padding: 0 0 20px;
        &.mobile {
            width: 100%;
            padding: 10px 0 30px;
            .v-btn {
                display: block;
                width: 100%;
            }
        }
        .v-btn {
            margin: 0 auto;
        }
    }

    .md-tabs {
        .md-tabs-navigation {
            background: #eee!important;
            .badge {
                position: absolute;
                background: #00c853;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                top: 34%;
                right: 30%;
            }
        }
        .md-tab {
            padding: 20px 2px 40px;
        }

        &.desktop {
            min-height: 75vh;
            .md-tabs-content {
                min-height: 75vh;
            }
            .md-field {
                margin-bottom: 30px;
            }
        }
    }

}
</style>
