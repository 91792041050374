<template>
<div id="">
  <v-layout  wrap tenant-row class="pa-3">
    <v-flex xs4>
      <span class="MRAIcon_Male" v-if="tenant.gender=='m'"><v-icon>people</v-icon>{{gender}}</span>
      <span class="'MRAIcon_Female'" v-if="tenant.gender=='f'"><v-icon>people</v-icon> {{gender}}</span>
      <br>
      <v-icon>location_city</v-icon> <span> {{nationality}}</span>
    </v-flex>
    <v-flex xs4>
      <div class="tenant-occupation">
        <v-icon>work</v-icon> {{occupation}}
      </div>
      <div class="tenant-school">
        <v-icon>domain</v-icon> {{tenant.school}}
      </div>
    </v-flex>
    <v-flex xs3>
      <div class="tenant-mid">
        <v-icon>flight_land</v-icon> {{mid}}
      </div>
      <div class="tenant-mod">
        <v-icon>flight_takeoff</v-icon> {{mod}}
      </div>
    </v-flex>
    <v-flex xs1>
      <v-icon @click="removeTenant" color="error">delete</v-icon>
    </v-flex>
  </v-layout>
</div>
</template>
<script>
import countries from "i18n-iso-countries"
import moment from 'moment'
export default {
  name: "",
  props: ['tenant','index'],
  data: () => ({
    mid:"",
    mod:"",
  }),
  methods: {
    removeTenant() {
      this.$emit('removeTenant',this.index)
    }
  },
  computed:{
    occupation(){
      switch(this.tenant.occupation.toLowerCase){
        case 'student':
          return this.$t("otherTenants.student")
        case 'worker':
          return this.$t("otherTenants.worker")
        default:
          return ""
      }
    },
    gender(){
      switch (this.tenant.gender) {
        case "m":
          return this.$t("profile.male")
        case "f":
          return this.$t("profile.female")
        default:
          return ""
      }
    },
    nationality(){
      countries.registerLocale(require("i18n-iso-countries/langs/"+this.$i18n.locale+".json"));
      return countries.getName(this.tenant.nationality, this.$i18n.locale)
    },
  },
  created() {
    //do something after creating vue instance

    this.mid= moment(this.tenant.moveInDate).format('MMM DD')
    this.mod=moment(this.tenant.moveOutDate).format('MMM DD')

  }
}
</script>
<style lang="scss" scoped>
.tenant-row{
  margin-bottom:1em;
}
</style>
