<template>
  <div id="">
    <div class="title">
      {{$t('uploads.room')}} {{index+1}}
    </div>
    <v-radio-group :hint="$t('features.bedTypeHint')" :label="$t('features.bedType')" v-model="$v.tRoom.bedType.$model" @change="setRoom">
      <v-radio v-for="(bedType, index) in bedTypes" color="primary" :label="bedType.text" :value="bedType.value" :key="index"></v-radio>
    </v-radio-group>
    <v-radio-group :hint="$t('features.sizeHint')" :label="$t('features.size')" v-model="$v.tRoom.size.$model" @change="setRoom">
      <v-radio v-for="(size, index) in roomSizes" color="primary" :label="size.text" :value="size.val" :key="index"></v-radio>
    </v-radio-group>
    <v-layout wrap>
      <v-flex xs12>

      <span class="v-label">{{$t('features.features')}}</span>
    </v-flex>
      <v-flex xs4 v-for="(feature,index) in roomFeatures" :key="index" :value="index">
        <v-checkbox v-model="tRoom.features" multiple color="primary" :value="index" @change="setFeature">
          <template slot="label">
            <span><span :class="feature.icon"></span> {{$t('features.'+index)}}</span>
          </template>

        </v-checkbox>

      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import {
  required
} from 'vuelidate/lib/validators'

export default {
  name: "",
  props:['room', 'index'],
  data: () => ({
    roomSizes: [{
        text:'<10 m² (< 3 ping)',
        val:'small',
    },
    {
      text: '10-15 m² (3-5 ping)',
      val:'average'
    },
    {
      text:'>15 m² (> 5 ping)',
      val:'large'
    }] ,
    roomFeatures: {
      privateBathroom: {
        value: false,
        icon: 'MRAIcon_private_bathroom',
      },
      ac: {
        value: true,
        icon: 'MRAIcon_air_conditioning',
      },
      fan: {
        value: false,
        icon: 'MRAIcon_fan',
      },
      outsideWindow: {
        value: true,
        icon: 'MRAIcon_outside_window',
      },
      innerWindow: {
        value: false,
        icon: 'MRAIcon_inner_window',
      },
      curtains: {
        value: false,
        icon: 'MRAIcon_curtains',
      },
      mattress: {
        value: true,
        icon: 'MRAIcon_mattress',
      },
      pillow: {
        value: true,
        icon: 'MRAIcon_pillow',
      },
      sheets: {
        value: true,
        icon: 'MRAIcon_sheets',
      },
      desk: {
        value: true,
        icon: 'MRAIcon_desk',
      },
      chair: {
        value: true,
        icon: 'MRAIcon_chair',
      },
      wardrobe: {
        value: true,
        icon: 'MRAIcon_wardrobe',
      },
      openHanger: {
        value: false,
        icon: 'MRAIcon_open_hanger',
      },
      shelve: {
        value: false,
        icon: 'MRAIcon_shelves',
      },
    },
  }),
  methods:{
    setRoom(){
      let payload= {
        index:this.index,
        room: this.tRoom
      }
      this.$emit('setRoom',payload)
    },
    setFeature(){
      this.$v.tRoom.features.$touch()
      this.setRoom()
    }
  },
  computed:{
    tRoom(){
      return this.room
    },
    bedTypes() {
      let t = ['None', 'Single', 'Double']

      if (this != undefined) {
        t = [{
            value: 'None',
            text: this.$t('features.bedTypeOptionNone')
          },
          {
            value: 'Single',
            text: this.$t('features.bedTypeOptionSingle')
          },
          {
            value: 'Double',
            text: this.$t('features.bedTypeOptionDouble')
          }
        ]
      }
      return t
    },
  },
  validations:{
    tRoom:{
      bedType: {
        required
      },
      size: {
        required
      },
      features:{

      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
