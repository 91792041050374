<template>
<div class="">
  <!-- <label v-if="checkLocation || this.user.roleValue>50" aria-hidden="true" :class="{selectError:anyError}" class="v-input v-label v-label--active theme--light autocomplete-label" style="left: 0px; right: auto; position: absolute;">{{$t('uploads.select')}}</label> -->
  <!-- <GmapAutocomplete v-if="checkLocation || this.user.roleValue>50" @place_changed="setPlace" :class="{selectError:anyError}" @onchange="touched" class="v-input v-text-field autocomplete-input" :placeholder="$t('uploads.startTyping')" :select-first-on-enter="true">

  </GmapAutocomplete> -->
  <vuetify-google-autocomplete
      id="map"
      append-icon="search"
      @placechanged="setPlace"
      :class="{selectError:anyError}"
      v-if="checkLocation || this.user.roleValue>50"
      :label="$t('uploads.select')"
      clearable
  >
  </vuetify-google-autocomplete>
  <small :class="{selectError:anyError}">{{errors[0]}}</small>
  <div class="">

    <l-map
      style="z-index:1!important; height:80vh; width: 100%"
      :zoom="zoom"
      :center="center"
    >

      <l-tile-layer :url="url"    attribution='<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>'></l-tile-layer>
      <l-marker v-if="cLocation!=undefined && cLocation.hasOwnProperty('lat') && cLocation.lat!=0" :icon="icon" :lat-lng="[cLocation.lat,cLocation.lng]">
      </l-marker>
    </l-map>
  </div>

</div>
</template>

<script>
export default {
  props:['errors','user','general', 'cLocation'],
  data: () => ({
    test:"taipei",
    center: {
      lat: 25.0247833,
      lng: 121.5359923
    },
    markers: [],
    zoom:14,
    url: 'https://a.tile.openstreetmap.de/{z}/{x}/{y}.png',
    icon: L.icon({
      iconUrl: 'https://res.cloudinary.com/my-room-abroad/image/upload/v1568260858/clusterIcons/blue_logo.png',
      iconSize: [60, 62],

    }),

  }),
  description: 'Autocomplete Example (#164)',
  methods: {
    setDescription(description) {
      this.description = description
    },
    setPlace(place) {
      // console.log('setPlace');
      if (place!=undefined && place!=null && place.hasOwnProperty('latitude') && place.hasOwnProperty('longitude')){
        console.log(place)
        const location = {
          lat: place.latitude,
          lng: place.longitude,
          address: place.name,
        }
        this.center=location
        // console.log('location sent',location)
        this.$emit('setLocation',location)
      }
    },
    touched(){
      console.log('oooh you touched my trlalala');
    },

  },
  computed:{
    checkLocation:{
      get(){
        if (this.cLocation!=undefined && this.cLocation.hasOwnProperty('lat') && this.cLocation.lat!=0){
          this.center=this.cLocation
        }
        if (this.cLocation!=undefined && this.cLocation.hasOwnProperty('lat') && this.cLocation.lat!=0 && this.general.complete){
          return false
        } else {
          return true
        }
      },
      set(){
      }
    },
    anyError(){
      if (this.errors.length>0) {
        console.log('there is error an error');
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
      setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 50);
  },
  components: {
  }

}
</script>
<style lang="scss" scoped>
.gsearch {
  color: black;
  font-weight: bold;
  font-size: 12px;
}

input {
  padding: 8px 5px 8px 28px;
  border: 1px solid rgba(0, 0, 0, 0.6);
}

.icon {
  position: absolute;
  left: 4px;
}

.md-layout {
  position: relative;
}

.md-icon {
  height: 38px;
}

.md-helper-text {
  margin: 0 !important;
}

.address-help {
  color: rgba(0, 0, 0, .54);
  margin-left: 1em;
  font-size: 12px;
}
.v-input {
    padding:0px;

}
.selectError{
  color:red !important;
  border-color:red !important;
}
.selectError::placeholder{
  color:red!important
}
.autocomplete-label{
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  transform-origin: top left;
  white-space: nowrap;
  transform: translateY(-18px) scale(.75);
  width:100%;

}
</style>
