<template>
  <div id="addListingCtr">
    <Navbar></Navbar>
    <br>
    <v-layout justify-center row wrap>
      <h1 class="justify-center">{{$t('newListing.listingType')}}</h1>

    </v-layout>
    <v-container grid-list-lg>
      <v-layout  justify-space-between>
        <v-flex xs12 sm4 md3 >

          <v-card hover @click="newApartmentDialog=true">
            <v-card-title class="centered justify-center" @click="newApartmentDialog=true">
              {{$t('uploads.apartment')}}

            </v-card-title>
            <v-card-text @click="newApartmentDialog=true">
              <v-layout  justify-center>
                  <v-icon large>domain</v-icon>
              </v-layout>
              <v-layout >

                <v-flex class="custom-card-text" justify-center xs12>
                  <p>{{$t('newListing.apartmentText')}}</p>
                </v-flex>
            </v-layout>
            </v-card-text>

            <v-card-actions click="newApartmentDialog=true">
                <v-btn flat color="primary" @click="newApartmentDialog=true">{{$t('newListing.createApartment')}} <v-icon right>arrow_forward</v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md3>
          <v-card hover href="/studio">
              <v-card-title class="centered justify-center">
                {{$t('uploads.studio')}}
              </v-card-title>
            <v-card-text>
              <v-layout  justify-center>
                  <v-icon large>hotel</v-icon>
              </v-layout>
              <v-layout >

                <v-flex class="custom-card-text" justify-center xs12>
                  {{$t('newListing.studioText')}}
                </v-flex>
            </v-layout>
            </v-card-text>

            <v-card-actions>
                <v-btn flat color="primary" href='/studio'>{{$t('newListing.createStudio')}} <v-icon right>arrow_forward</v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md3>
          <v-card hover href="/dashboard">
              <v-card-title class="centered justify-center">
                {{$t('uploads.room')}}
              </v-card-title>
            <v-card-text>
              <v-layout  justify-center>
                  <v-icon large>meeting_room</v-icon>
              </v-layout>
              <v-layout >

                <v-flex class="custom-card-text" justify-center xs12>
                  <p>{{$t('newListing.roomText')}}</p>
                </v-flex>
            </v-layout>
            </v-card-text>

            <v-card-actions>
                <v-btn flat color="primary" href='/dashboard'>{{$t('newListing.viewDashboard')}}<v-icon right>arrow_forward</v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout justify-space-between wrap>
        <v-flex xs12 sm4 md3 >
          <v-btn color="primary" flat href="https://youtube.com/watch?v=yrOZuHRuf9M" target="blank">{{$t('newListing.howToAddApt')}}</v-btn>
        </v-flex>
        <v-flex xs12 sm4 md3 >
            <v-btn color="primary" flat href="https://youtube.com/watch?v=v91RkrULR9I" target="blank">{{$t('newListing.howToAddStudio')}}</v-btn>
        </v-flex>
        <v-flex xs12 sm4 md3 >
          <v-btn color="primary" flat href="https://www.youtube.com/watch?v=yrOZuHRuf9M&t=99" target="blank">{{$t('newListing.howToAddRoom')}}</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="newApartmentDialog"
      width="80vw"
    >
      <v-card>
        <v-card-title
          class="headline justify-center"
          justify-center
          primary-title
        >
          {{$t('newListing.createApartment')}}
        </v-card-title>

        <v-card-text>
          <v-container grid-list-xs>
            <v-layout  wrap>
              <v-flex xs12 md6 class="pa-3">
                <v-card hover href="/newApartment">
                  <v-card-title class="centered justify-center">
                    {{$t("newListing.apartmentType")}}
                  </v-card-title>
                  <v-card-text>
                    <v-layout  justify-center>
                        <v-icon large>meeting_room</v-icon>
                    </v-layout>
                    <v-layout >

                      <v-flex class="custom-card-text" justify-center xs12>
                        <p>{{$t("newListing.roomContractText")}}</p>
                      </v-flex>
                  </v-layout>
                  </v-card-text>

                  <v-card-actions>
                      <v-btn flat color="primary" href='/newApartment'>{{$t("newListing.createApartment")}} <v-icon right>arrow_forward</v-icon></v-btn>
                  </v-card-actions>
                </v-card>

              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 md6 class="pa-3" >
                <v-card hover href="/apartment">
                  <v-card-title class="centered justify-center">
                    {{$t("newListing.wholeFlat")}}
                  </v-card-title>
                  <v-card-text>
                    <v-layout  justify-center>
                        <v-icon large>domain</v-icon>
                    </v-layout>
                    <v-layout >

                      <v-flex class="custom-card-text" justify-center xs12>
                        <p>{{$t("newListing.completeApartmentText")}}</p>
                      </v-flex>
                  </v-layout>
                  </v-card-text>

                  <v-card-actions>
                      <v-btn flat color="primary" href='/apartment'>{{$t("newListing.createApartment")}}<v-icon right>arrow_forward</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            flat
            @click="newApartmentDialog = false"
          >
            {{$t("userRequest.cancel")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    newApartmentDialog:false
  })
}
</script>
<style lang="scss" scoped>
#addListingCtr{
     min-height: 100vh;
}
.custom-card-text{
  padding-top: .875rem;
}
</style>
